import { createSlice } from "@reduxjs/toolkit";
import { MerchantCouponsThunk } from "../Thunk";
import { RootState } from "../../..";
import { Coupon } from "../../../../utilities/Interfaces/Merchant";

interface InitialState {
    loading: 0 | 1 | 2 | 3;
    error?: string;
    result: Coupon[]
}

const initialState: InitialState = {
    loading: 0,
    error: undefined,
    result: []
}

const MerchantCoupons = createSlice({
    name: "Homepage-top-coupons",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(MerchantCouponsThunk.pending,  (state) => {
            state.loading =  1;
            state.error = undefined;
        })

        builder.addCase(MerchantCouponsThunk.rejected,  (state, action) => {
            state.loading = 2;
            state.error =  action.error.message;
        })

        builder.addCase(MerchantCouponsThunk.fulfilled,   (state, action) => {
            state.loading = 3;
            state.result = action.payload;
        });
    }
})

export const $MerchantCoupons_loading = (state:RootState) => state.MerchantCoupons.loading;
export const $MerchantCoupons_error = (state:RootState) => state.MerchantCoupons.error;
export const $MerchantCoupons_data = (state:RootState) => state.MerchantCoupons.result;

export const MerchantCouponsReducer = MerchantCoupons.reducer