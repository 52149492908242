import { createSlice } from "@reduxjs/toolkit";
import { CategoryNextCouponsThunk, CategoryCouponsThunk } from "../Thunk";
import { RootState } from "../..";
import { CategoriesResponse } from "../../../utilities/Interfaces/Coupons";

interface initialState {
    loading: 0 | 1 | 2 | 3;
    error?: string;
    result: CategoriesResponse
}

const initialState: initialState = {
    loading: 0,
    error: undefined,
    result: {
        pagination: {
            currentPage: 0,
            hasNextPage:  false,
            hasPreviousPage:  false,
            totalPages:  0,
            totalCoupons:   0
        },
        coupons: []
    }
}

const CouponCategorySlice = createSlice({
    name: "CouponsCategory",
    initialState,
    reducers: {},
    extraReducers: (builder) => {        
        builder.addCase(CategoryCouponsThunk.pending,  (state) => {
            state.loading =  1;
            state.error = undefined;
        })

        builder.addCase(CategoryCouponsThunk.rejected,  (state, action) => {
            state.loading = 2;
            state.error =  action.error.message;
        })

        builder.addCase(CategoryCouponsThunk.fulfilled,   (state, action) => {
            const data = action.payload;

            if (data.pagination) {
                state.loading = 3;
                state.result = action.payload;
                return;
            }

            state.loading = 2;
            state.error = (data as any).message;
        });
        
        builder.addCase(CategoryNextCouponsThunk.pending,  (state) => {
            state.loading =  1;
            state.error = undefined;
        })

        builder.addCase(CategoryNextCouponsThunk.rejected,  (state, action) => {
            state.loading = 2;
            state.error =  action.error.message;
        })

        builder.addCase(CategoryNextCouponsThunk.fulfilled,   (state, action) => {
            const data = action.payload;

            if (data.pagination) {
                state.loading = 3;
                state.result = action.payload;
                return;
            }

            state.loading = 2;
            state.error = (data as any).message;
        });
    }
})

export const $CouponCategory_loading = (state:RootState) => state.CouponsCategory.loading;
export const $CouponCategory_error = (state:RootState) => state.CouponsCategory.error;
export const $CouponCategory_data = (state:RootState) => state.CouponsCategory.result.coupons;
export const $CouponCategory_data_pagination = (state:RootState) => state.CouponsCategory.result.pagination;

export const CouponCategoryReducer = CouponCategorySlice.reducer