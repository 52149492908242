import { useDispatch, useSelector } from "react-redux";
import { ProductCard } from "./sub components/ProductCard";
import { AppDispatch } from "../Store";
import { useEffect, useState } from "react";
import DealImage from "../components/DealImage";
import { Link } from "react-router-dom";
import { ChevronRight } from "lucide-react";
import { $Categorties_data, $Categorties_loading } from "../Store/Categories/Slice";
import { CategortiesThunk } from "../Store/Categories/Thunk";
import { $Products_data, $Products_data_totalPages, $Products_data_totalProducts, $Products_error, $Products_loading } from "../Store/Products/Slice";
import { AllProductsThunk, NextProductsThunk } from "../Store/Products/Thunk";
import PaginatedList from "./sub components/Pagination";
import clsx from "clsx";
import { Helmet } from "react-helmet";
import SearchBar from "./sub components/SearchBar";

export default function ProductsPage() {
    const dispatch = useDispatch<AppDispatch>();
    const queryParams = new URLSearchParams(window.location.search);
    const page = Number(queryParams.get('page')) ?? 1;

    const [hamburgerMenu, setHamburgerMenu] = useState(false);

    const Categorties_loading = useSelector($Categorties_loading);
    const Categorties_data = useSelector($Categorties_data);

    const Products_loading = useSelector($Products_loading);
    const Products_error = useSelector($Products_error);
    const Products_data = useSelector($Products_data);
    const Products_data_totalPages = useSelector($Products_data_totalPages);
    const Products_data_totalProducts = useSelector($Products_data_totalProducts);

    useEffect(() => {
        dispatch(CategortiesThunk());
        if (page) {
            dispatch(NextProductsThunk({ page: Number(page) }));
            return;
        }
        dispatch(AllProductsThunk());
    }, [dispatch, page]);

    const getMetaDescription = () => {
        const totalDeals = Products_data_totalProducts;
        const categories = [Categorties_data.map((e)=> e.category)];
        const maxDiscount = 90;

        return `Find ${totalDeals} amazing deals across ${categories.length} categories with discounts up to ${maxDiscount}% off. Browse our curated collection of coupons and special offers on ${categories.join(', ')}.`;
    };

    const getTitle = () => {
        return 'Best Deals, Coupons & Discounts | Save Up To 90% Off';
    };

    const getCanonicalUrl = () => {
        const baseUrl = `${window.location.origin}/products`;
        return baseUrl;
    };


    return (
        <>
            {Categorties_loading === 3 && Products_loading === 3 && <Helmet>
                {/* Basic Meta Tags */}
                <title>{getTitle()}</title>
                <meta name="description" content={getMetaDescription()} />
                <link rel="canonical" href={getCanonicalUrl()} />

                {/* OpenGraph Meta Tags */}
                <meta property="og:title" content={getTitle()} />
                <meta property="og:description" content={getMetaDescription()} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={getCanonicalUrl()} />
                <meta property="og:site_name" content="Deals Daily" />

                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={getTitle()} />
                <meta name="twitter:description" content={getMetaDescription()} />

                {/* Additional SEO Meta Tags */}
                <meta name="robots" content="index, follow" />
                {page > 1 && <meta name="robots" content="noindex, follow" />}
                <meta name="keywords" content={`deals, coupons, discounts, ${Categorties_data.map((e)=> e.category).join(', ')}`} />

                {/* Pagination Meta Tags */}
                {page > 1 && (
                    <link
                        rel="prev"
                        href={`${getCanonicalUrl()}?page=${page - 1}`}
                    />
                )}
                {Products_data.length === 20 && (
                    <link
                        rel="next"
                        href={`${getCanonicalUrl()}?page=${page + 1}`}
                    />
                )}
            </Helmet>}
            <div className="min-h-screen lg:px-20 md:px-10 px-3 py-3 bg-black/5 grid sm:grid-cols-[auto_1fr] gap-3">
                <div className="bg-white sm:w-56 relative border h-fit sm:py-10 py-5 text-left z-20">
                    <h2 className="text-lg font-bold text-gray-800 mb-3 px-6 flex items-center justify-between">
                        <span>Top Product Categories</span>
                        <div onClick={()=>setHamburgerMenu(!hamburgerMenu)} className="max-sm:grid hidden gap-[5px] cursor-pointer active:scale-90">
                            <div className={clsx(
                                "h-[3px] w-8",
                                hamburgerMenu ? "bg-purple-500" : "bg-black"
                            )}></div>
                            <div className={clsx(
                                "h-[3px] w-8",
                                hamburgerMenu ? "bg-purple-500" : "bg-black"
                            )}></div>
                            <div className={clsx(
                                "h-[3px] w-8",
                                hamburgerMenu ? "bg-purple-500" : "bg-black"
                            )}></div>
                        </div>
                    </h2>
                    {Categorties_loading !== 3 && <div className="px-6 grid max-sm:hidden gap-4">
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                    </div>}

                    {Categorties_loading === 3 && <div className={clsx(
                        "max-sm:absolute top-full w-full bg-white max-sm:shadow-lg",
                        hamburgerMenu ? "max-sm:h-[50vh] max-sm:overflow-y-auto" : "max-sm:h-0 max-sm:overflow-hidden"
                    )}>
                        <Link
                            to={`#`}
                            key={"001"}
                            className={clsx(
                                "sticky top-0 w-full px-6 block py-3 border-b last:border-none group font-semibold bg-purple-500 text-white",
                            )}>
                            <span className={clsx(
                                "flex items-center justify-between text-white",
                            )}>
                                All Top Products <ChevronRight className='group-hover:rotate-12 group-active:rotate-90' />
                            </span>
                        </Link>
                        {Categorties_data.map((category) => (
                            <Link to={`/products/${category.category}`} key={category.category} className="w-full px-6 hover:bg-black/5 block py-3 border-b last:border-none group">
                                <span className="flex items-center justify-between group-hover:text-blue-500">
                                    {category.category} <ChevronRight className='group-hover:rotate-12 group-active:rotate-90' />
                                </span>
                            </Link>
                        ))}
                    </div>}
                </div>
                <div className="flex flex-col gap-3">
                    <div className="bg-white text-left p-10 border">
                        <h1 className="text-2xl font-semibold">Partner Merchants & Exclusive Offers</h1>
                        <p className="sm:max-w-[80%] mt-2">
                            Explore our trusted merchants offering a variety of products and exclusive coupons. Discover amazing deals and save on your favorite brands all in one place!
                        </p>
                    </div>
                    <SearchBar />
                    {Products_loading === 3 && Products_data.length > 0 && <div className="bg-white smp-10 p-3 gap-y-4 sm:grid-cols-[repeat(auto-fill,minmax(15rem,1fr))] grid-cols-[repeat(auto-fill,minmax(12rem,1fr))] grid border">
                        {Products_data.map((product, index) => (
                            <ProductCard
                                key={index}
                                discount={Number(product.discount_percentage)}
                                discountedPrice={String(Number(product.price))}
                                imageUrl={product.image_url ?? ""}
                                originalPrice={product.compare_at_price}
                                title={product.title}
                                productUrl={product.product_url}
                            />
                        ))}
                    </div>}

                    {Products_loading === 3 && Products_data.length === 0 && <div className="bg-white p-10 grid border">
                        <span className="text-2xl opacity-60">No Data</span>
                    </div>}
                    {Products_loading === 2 && <div className="bg-white p-10 grid border">
                        <span className="text-2xl text-red-500">{Products_error}</span>
                    </div>}

                    {Products_loading !== 3 && <div className="bg-white md:p-10 p-3 sm:grid-cols-[repeat(auto-fill,minmax(15rem,1fr))] grid-cols-[repeat(auto-fill,minmax(12rem,1fr))] grid gap-4 border">
                        {new Array(10).fill("").map((_, $) => (
                            <div key={$} className="h-fit animate-pulse border shadow-lg rounded-md">
                                <div className="border-b">
                                    <DealImage
                                        src=""
                                        alt=""
                                        className="w-full h-48 object-cover"
                                    />
                                </div>
                                <div className="p-4 grid gap-1">
                                    <div className="p-1 bg-black/10 animate-pulse rounded-2xl">{_}</div>
                                    <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                                </div>
                                <div className="px-4 grid grid-cols-2 gap-1">
                                    <div className="p-1 bg-black/10 animate-pulse rounded-2xl">{_}</div>
                                    <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                                </div>
                                <div className="p-4 grid gap-1">
                                    <div className="p-2 bg-purple-500 animate-pulse rounded-2xl text-center text-white">Loading</div>
                                </div>
                            </div>
                        ))}
                    </div>}
                    {Products_data_totalPages > 1 && < PaginatedList forcePage={page ? Number(page) - 1 : 0} dispatchThunk={NextProductsThunk} pageCount={Products_data_totalPages} />}
                </div>
            </div>
        </>
    )
}
