import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../utilities/important";
import { Merchant } from "../../../utilities/Interfaces/Homepage-top-coupons";

export interface Result {
    data: Merchant[],
    currentPage: number,
    totalPages: number,
    totalItems: number,
};

export const HomepageTopCouponsThunk = createAsyncThunk(
    "Homepage-top-coupons/thunk",
    async (): Promise<Result> => {
        if (!baseUrl) throw new Error("baseUrl is not defined");

        const url = `${baseUrl}/homepage/top-coupons`;
        const response = await fetch(url);
        const data = await response.json();
        return data;
    }
)