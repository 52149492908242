import { createSlice } from "@reduxjs/toolkit";
import { AllProductsThunk, NextProductsThunk, Result } from "../Thunk";
import { RootState } from "../..";

interface InitialState {
    loading: 0 | 1 | 2 | 3;
    error?: string;
    result: Result
}

const initialState: InitialState = {
    loading: 0,
    error: undefined,
    result: {
        currentPage: 0,
        data: [],
        totalItems: 0,
        totalPages: 0
    }
}

const ProductsSlice = createSlice({
    name: "Products",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(AllProductsThunk.pending,  (state) => {
            state.loading =  1;
            state.error = undefined;
        })

        builder.addCase(AllProductsThunk.rejected,  (state, action) => {
            state.loading = 2;
            state.error =  action.error.message;
        })

        builder.addCase(AllProductsThunk.fulfilled,   (state, action) => {
            state.loading = 3;
            state.result = action.payload;
        });
        builder.addCase(NextProductsThunk.pending,  (state) => {
            state.loading =  1;
            state.error = undefined;
        })

        builder.addCase(NextProductsThunk.rejected,  (state, action) => {
            state.loading = 2;
            state.error =  action.error.message;
        })

        builder.addCase(NextProductsThunk.fulfilled,   (state, action) => {
            state.loading = 3;
            state.result = action.payload;
        });
    }
})

export const $Products_loading = (state:RootState) => state.Products.loading;
export const $Products_error = (state:RootState) => state.Products.error;
export const $Products_data = (state:RootState) => state.Products.result?.data;
export const $Products_data_totalPages = (state:RootState) => state.Products.result?.totalPages;
export const $Products_data_totalProducts = (state:RootState) => state.Products.result?.totalItems;

export const ProductsReducer = ProductsSlice.reducer