import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className='grid place-items-center p-5 border-t shadow-[0_-20px_25px_-5px_rgb(0,0,0,0.025)] relative'>
      <p>&copy; 2024 DealsBunny. All rights reserved.</p>
      <p className='absolute pointer-events-none opacity-0'>Designed by: <Link to={"https://fabiconcept.online"}>Fabiconcept</Link></p>
    </footer>
  );
};

export default Footer;
