import React, { useState, useEffect, useRef, FormEvent } from 'react';
import { Search, ChevronLeft, ChevronRight } from 'lucide-react';
import Carousel from '../components/Carousel';
import { CouponCard } from './sub components/CouponCard';
import { ProductCard } from './sub components/ProductCard';
import { useDispatch, useSelector } from 'react-redux';
import { HomepageTopCouponsThunk } from '../Store/Homepage-top-coupons/Thunk';
import { AppDispatch } from '../Store';
import { $HomepageTopCoupons_data } from '../Store/Homepage-top-coupons/Slice';
import { HomepageTopProductsThunk } from '../Store/Homepage-top-products/Thunk';
import { $HomepageTopProducts_data } from '../Store/Homepage-top-products/Slice';
import Modal from '../components/Modal';
import { AnimatePresence } from 'framer-motion';
import { Merchant } from '../utilities/Interfaces/Homepage-top-coupons';
import { $HomepageTopMerchants_data } from '../Store/Homepage-top-merchants/Slice';
import { HomepageTopMerchantsThunk } from '../Store/Homepage-top-merchants/Thunk';
import { Link, useNavigate } from 'react-router-dom';
import DealImage from '../components/DealImage';
import { openInNewTab } from '../utilities/important';
import { Helmet } from 'react-helmet';

interface Slide {
  title: string;
  description: string;
  buttonText: string;
  bgColor: string;
  textColor: string;
}

const DealsBunnyHomepage: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [modal, setModal] = useState<{
    isOpen: boolean,
    data: Merchant,
  }>({
    isOpen: false,
    data: {} as Merchant
  });

  const location = useNavigate()
  const couponSliderRef = useRef<HTMLDivElement>(null);
  const productSliderRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();

  const homepageTopCoupons_data = useSelector($HomepageTopCoupons_data);
  const homepageTopProducts_data = useSelector($HomepageTopProducts_data);
  const HomepageTopMerchants_data = useSelector($HomepageTopMerchants_data);

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e: FormEvent) => {
    e.preventDefault();
    const term = encodeURI(searchTerm);
    if (!term) return;

    location(`/search?search=${term}`)
  };

  useEffect(() => {
    dispatch(HomepageTopCouponsThunk());
    dispatch(HomepageTopProductsThunk());
    dispatch(HomepageTopMerchantsThunk());
  }, [dispatch]);

  const slides: Slide[] = [
    {
      title: "Holiday Hopping Deals!",
      description: "Unwrap savings up to 70% off on festive favorites",
      buttonText: "Shop Holiday Deals",
      bgColor: "bg-gray-100",
      textColor: "text-gray-800"
    },
    {
      title: "Summer Carrot Bonanza",
      description: "Cool deals for hot days - up to 60% off summer essentials",
      buttonText: "Get Summer Savings",
      bgColor: "bg-blue-50",
      textColor: "text-gray-800"
    },
    {
      title: "Tech Bunny Bargains",
      description: "Hop into the future with up to 50% off latest gadgets",
      buttonText: "Explore Tech Deals",
      bgColor: "bg-gray-200",
      textColor: "text-gray-800"
    },
    {
      title: "Green Paw Discounts",
      description: "Eco-friendly products at nature-loving prices - 40% off",
      buttonText: "Shop Eco Deals",
      bgColor: "bg-green-50",
      textColor: "text-gray-800"
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);
    return () => clearInterval(timer);
  }, [slides.length]);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };


  return (
    <>
      <Helmet>
        <title>Best Deals & Coupons: Save on 18+ Categories | Deals Bunny</title>

        <meta name="description" content="Discover unbeatable deals, coupons, and promo codes across . Find top bargains, discounts, and price drops on accessories, apparel, auto, travel, and more. Save big with Deals Bunny." />

        <meta property="og:title" content="Deals Bunny Shopping - Best Deals, Coupons & Discounts on Shop Online, Compare Prices & Where to Buy" />

        <meta property="og:description" content="Your one-stop shop for amazing deals and savings. Find the best bargains, money-saving offers, promo codes, and price comparisons across accessories, apparel, fashion, home, auto, and more." />

        <meta property="og:type" content="website" />

        <meta property="og:image" content="https://dealsbunny/images/og-bunny.png" />

        <meta property="og:determiner" content="auto" />
      </Helmet>
      <div className="min-h-screen bg-gray-50 ">

        {/* Hero Slider */}
        <section className="relative h-96">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`absolute top-0 left-0 w-full h-full ${slide.bgColor} ${slide.textColor} ${index === currentSlide ? 'opacity-100' : 'opacity-0'
                } transition-opacity duration-1000 ease-in-out flex items-center justify-center`}
            >
              <div className="text-center px-8">
                <h2 className="md:text-4xl text-2xl md:font-bold font-medium mb-4">{slide.title}</h2>
                <p className="sm:text-xl mb-8">{slide.description}</p>
                <button className="bg-purple-600 text-white sm:font-bold font-medium py-2 px-4 rounded-full hover:bg-purple-700 transition duration-300">
                  {slide.buttonText}
                </button>
              </div>
            </div>
          ))}
          <button
            onClick={prevSlide}
            className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 hover:bg-opacity-75 transition duration-300"
          >
            <ChevronLeft className="text-gray-800" />
          </button>
          <button
            onClick={nextSlide}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 hover:bg-opacity-75 transition duration-300"
          >
            <ChevronRight className="text-gray-800" />
          </button>
        </section>

        {/* Search Bar */}
        <div className="container mx-auto mt-8 sm:px-10 px-5">
          <form onSubmit={handleSearch} className="relative">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search for hoppin' deals..."
              className="w-full px-10 py-5 rounded-full shadow-md focus:shadow-xl focus:shadow-purple-500/10 focus:outline-purple-600/25 outline-transparent"
            />
            <Search role='button' className="absolute right-10 top-1/2 -translate-y-1/2 cursor-pointer active:scale-90 text-gray-400" />
          </form>
        </div>

        {/* Featured Merchants */}
        {HomepageTopMerchants_data.length > 0 && <section className="container mx-auto mt-12">
          <div className='flex justify-between flex-wrap items-baseline py-5 px-8'>
            <h3 className="md:text-2xl sm:text-xl text-lg md:font-bold font-medium mb-4 text-gray-800">Bunny-Approved Merchants</h3>
            <Link to={"/merchants"} className='sm:px-8 px-5 sm:py-3 py-2 border border-purple-500 rounded-3xl text-center text-purple-500 hover:text-white hover:bg-purple-500 hover:border-transparent active:scale-90 active:opacity-70 cursor-pointer select-none'>See more</Link>
          </div>
          <div className="grid md:sm:grid-cols-[repeat(auto-fill,minmax(15rem,1fr))] grid-cols-[repeat(auto-fill,minmax(12rem,1fr))] mx-auto gap-4 px-3">
            {HomepageTopMerchants_data.slice(0, 5).map((merchant, _) => (
              <Link to={`./merchant/${merchant.merchant_number}`} key={_} className="bg-white rounded-lg border border-transparent hover:border-purple-500 hover:scale-[1.05] active:opacity-50 shadow-md p-4 text-center cursor-pointer active:scale-95 relative">
                {/* <img src={`/api/placeholder/100/100?text=${merchant}`} alt={merchant} className="mx-auto mb-2" /> */}
                <DealImage alt={merchant.merchant_name} src={merchant.logoimageurl} className="mx-auto mb-2 h-20 w-full absolute top-0 left-0 object-cover p-2 opacity-5" />
                <DealImage alt={merchant.merchant_name} src={merchant.logoimageurl} className="mx-auto mb-2 h-20 w-[80%] object-cover p-2 relative z-10 mix-blend-multiply brightness-110" />
                <p className="font-semibold text-gray-700">{merchant.merchant_name}</p>
              </Link>
            ))}
          </div>
        </section>}

        {/* Today's Carrot Crop of Deals */}
        {<section className="container mt-12 m-auto">
          <div className='flex justify-between flex-wrap items-baseline py-5 px-8'>
            <h3 className="md:text-2xl sm:text-xl text-lg md:font-bold font-medium mb-4 text-gray-800">Today's Carrot Crop of Deals</h3>
            <Link to={"/coupons"} className='sm:px-8 px-5 sm:py-3 py-2 border border-purple-500 rounded-3xl text-center text-purple-500 hover:text-white hover:bg-purple-500 hover:border-transparent active:scale-90 active:opacity-70 cursor-pointer select-none'>See more</Link>
          </div>
          <div className="relative">
            <div ref={couponSliderRef} className="w-full px-4">
              <Carousel>
                {homepageTopCoupons_data.length > 0 && homepageTopCoupons_data.map((deal, index) => (
                  <CouponCard
                    key={index}
                    code={deal.code}
                    merchant={deal.merchant_name}
                    merchantId={deal.merchant_id}
                    logo={deal.logo_image_url}
                    expiry={deal.expiration_date}
                    title={deal.name}
                    grabCarrot={() => {
                      setModal((prev) => ({ ...prev, isOpen: true, data: { ...deal } }));
                      openInNewTab(deal.redirect_url);
                      navigator.clipboard.writeText(deal.code);
                    }}
                  />
                ))}
                {homepageTopCoupons_data.length === 0 && new Array(10).fill("").map((_, $) => (
                  <div key={$} className="h-fit animate-pulse border shadow-lg rounded-md">
                    <div className="border-b">
                      <DealImage
                        src=""
                        alt=""
                        className="w-full h-48 object-cover"
                      />
                    </div>
                    <div className="p-4 grid gap-1">
                      <div className="p-1 bg-black/10 animate-pulse rounded-2xl">{_}</div>
                      <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                    </div>
                    <div className="px-4 pb-4 grid gap-1">
                      <div className="p-2 bg-purple-500 animate-pulse rounded-2xl text-center text-white">Loading</div>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </section>}

        {/* Hoppin' Hot Product Deals */}
        <section className="container mx-auto mt-12 mb-12">
          <div className='flex justify-between flex-wrap items-baseline py-5 px-8'>
            <h3 className="md:text-2xl sm:text-xl text-lg md:font-bold font-medium mb-4 text-gray-800">Hoppin' Hot Product Deals</h3>
            <Link to={"/products"} className='sm:px-8 px-5 sm:py-3 py-2 border border-purple-500 rounded-3xl text-center text-purple-500 hover:text-white hover:bg-purple-500 hover:border-transparent active:scale-90 active:opacity-70 cursor-pointer select-none'>See more</Link>
          </div>
          <div className="relative">
            <div ref={productSliderRef} className="pb-4 px-4 flex items-stretch">
              {homepageTopProducts_data.length > 0 && <Carousel>

                {homepageTopProducts_data.map((product, index) => (
                  <ProductCard
                    key={index}
                    discount={Number(product.discount_percentage)}
                    discountedPrice={String(Number(product.price))}
                    imageUrl={product.image_url ?? ""}
                    originalPrice={product.compare_at_price}
                    title={product.title}
                    productUrl={product.product_url}
                  />
                ))}
              </Carousel>}

              {homepageTopProducts_data.length === 0 && <Carousel>
                {new Array(10).fill("").map((_, $) => (
                  <div key={$} className="h-fit animate-pulse border shadow-lg rounded-md">
                    <div className="border-b">
                      <DealImage
                        src=""
                        alt=""
                        className="w-full h-48 object-cover"
                      />
                    </div>
                    <div className="p-4 grid gap-1">
                      <div className="p-1 bg-black/10 animate-pulse rounded-2xl">{_}</div>
                      <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                    </div>
                    <div className="px-4 grid grid-cols-2 gap-1">
                      <div className="p-1 bg-black/10 animate-pulse rounded-2xl">{_}</div>
                      <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                    </div>
                    <div className="p-4 grid gap-1">
                      <div className="p-2 bg-purple-500 animate-pulse rounded-2xl text-center text-white">Loading</div>
                    </div>
                  </div>
                ))}
              </Carousel>}
            </div>
          </div>
        </section>

        {/* Footer remains the same */}
        <AnimatePresence>
          {modal.isOpen && <Modal data={modal.data} handleClickAway={() => setModal((prev) => ({ ...prev, isOpen: false }))} />}
        </AnimatePresence>
      </div>
    </>
  );
};

export default DealsBunnyHomepage;
