import { Search } from "lucide-react";
import { client, openInNewTab, updateSearchPageParam } from "../utilities/important";
import React, { useEffect, useRef, useState } from 'react'
import { $Categorties_data, $Categorties_loading } from "../Store/Categories/Slice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../Store";
import { CategortiesThunk } from "../Store/Categories/Thunk";
import { ProductCard } from "./sub components/ProductCard";
import { Product } from "../utilities/Interfaces/Products";
import PaginatedList from "./sub components/Pagination";
import { Coupon } from "../utilities/Interfaces/Coupons";
import { CouponCard } from "./sub components/CouponCard";
import { AnimatePresence } from "framer-motion";
import Modal from "../components/Modal";
import clsx from "clsx";

export default function SearchPage() {
    const dispatch = useDispatch<AppDispatch>();

    const [modal, setModal] = useState<{
        isOpen: boolean,
        data: Coupon,
    }>({
        isOpen: false,
        data: {} as Coupon
    });

    const queryParams = new URLSearchParams(window.location.search);
    const urlCategories = queryParams.get('categories');
    const productPage = queryParams.get('product-page');
    const couponPage = queryParams.get('coupon-page');
    const urlSearch = queryParams.get('search') ?? "";

    const inputRef = useRef<HTMLInputElement>(null);

    const [hamburgerMenu, setHamburgerMenu] = useState(false);
    
    const Categorties_loading = useSelector($Categorties_loading);
    const Categorties_data = useSelector($Categorties_data);

    const [searchQuery,  setSearchQuery] = React.useState(urlSearch);
    const [searchCategory, setSearchCategory] = React.useState<string[]>([]);

    const [couponsResults, setCouponsResults] = React.useState<Coupon[][]>([[]]);
    const [totalCouponsResults, setTotalCouponsResults] = React.useState<number>(0);
    const [couponsProcessingTime, setTotalCouponsProcessingTime] = React.useState<number>(0);
    const [productsResults, setProductsResults] = React.useState<Product[][]>([[]]);
    const [totalProductsResults, setTotalProductsResults] = React.useState<number>(0);
    const [productsProcessingTime, setTotalProductsProcessingTime] = React.useState<number>(0);
    

    useEffect(() => {
        if(!urlCategories) return;

        const categories = urlCategories.split(',');
        setSearchCategory(categories);
    }, [urlCategories]);

    useEffect(() => {
        dispatch(CategortiesThunk());
    }, [dispatch]);

    useEffect(() => {
        if(!inputRef.current) return;
        inputRef.current.focus();
    }, [inputRef]);

    const updateUrlWithCategories = (categories: string[]) => {
        updateSearchPageParam("categories", categories.join(','))
    };

    const handleCategoryChange = (category: string) => {
        setSearchCategory((prevCategories) => {
            const newCategories = prevCategories.includes(category)
                ? prevCategories.filter((cat) => cat !== category) // Remove if already selected
                : [...prevCategories, category]; // Add if not selected
            updateUrlWithCategories(newCategories); // Update the URL
            return newCategories;
        }
        );
    };

    const performProductSearch = React.useCallback((page: number = 0) => {
        const filterQuery = searchCategory.map((cat) => `category = '${cat}'`).join(" OR ");

        client
            .index("products")
            .search(searchQuery, {
                filter: filterQuery ? [filterQuery] : undefined,
                offset: page * 9,
                limit:  9,
            })
            .then((results) => {
                const hits = results.hits as Product[];
                setTotalProductsResults(results.estimatedTotalHits);
                setTotalProductsProcessingTime(results.processingTimeMs);
                setProductsResults([hits]);
            })
            .catch((error) => console.error("Error performing search:", error));
    }, [searchQuery, searchCategory]);
    
    const performCouponsearch = React.useCallback((page: number = 0) => {
        const filterQuery = searchCategory.map((cat) => `category = '${cat}'`).join(" OR ");

        client
            .index("coupons")
            .search(searchQuery, {
                filter: filterQuery ? [filterQuery] : undefined,
                offset: page * 9,
                limit:  9,
            })
            .then((results) => {
                const hits = results.hits as Coupon[];
                setTotalCouponsResults(results.estimatedTotalHits);
                setTotalCouponsProcessingTime(results.processingTimeMs);
                setCouponsResults([hits]);
            })
            .catch((error) => console.error("Error performing search:", error));
    }, [searchQuery, searchCategory]);


    useEffect(() => {
        performProductSearch();
        performCouponsearch()
    }, [searchQuery, searchCategory, performProductSearch, performCouponsearch]);

    useEffect(() => {
        updateSearchPageParam("search",searchQuery)
    }, [searchQuery]);

    return (
        <div className="min-h-screen lg:px-20 md:px-10 px-3 py-3 bg-black/5 sm:grid max-sm:flex max-sm:flex-col sm:grid-cols-[auto_1fr] gap-3">
            <div className="bg-white sm:w-56 relative border h-fit sm:py-10 py-5 text-left z-20">
                <h2 className="text-lg font-bold text-gray-800 mb-3 px-6 flex items-center justify-between">
                    <span>Categories</span>
                    <div onClick={() => setHamburgerMenu(!hamburgerMenu)} className="max-sm:grid hidden gap-[5px] cursor-pointer active:scale-90">
                        <div className={clsx(
                            "h-[3px] w-8",
                            hamburgerMenu ? "bg-purple-500" : "bg-black"
                        )}></div>
                        <div className={clsx(
                            "h-[3px] w-8",
                            hamburgerMenu ? "bg-purple-500" : "bg-black"
                        )}></div>
                        <div className={clsx(
                            "h-[3px] w-8",
                            hamburgerMenu ? "bg-purple-500" : "bg-black"
                        )}></div>
                    </div>
                </h2> 
                {Categorties_loading !== 3 && <div className="px-6 grid gap-4">
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                    </div>}
                {Categorties_loading === 3 && <div className={clsx(
                    "max-sm:absolute top-full w-full bg-white max-sm:shadow-lg h-fit",
                    hamburgerMenu ? "max-sm:h-[50vh] max-sm:overflow-y-auto" : "max-sm:h-0 max-sm:overflow-hidden"
                )}>
                    {Categorties_data.map((category, index) => (
                        <label key={index} className="h-fit flex items-center px-6 gap-2 cursor-pointer">
                            <input
                                type="checkbox"
                                ref={inputRef}
                                className="input"
                                onChange={() => handleCategoryChange(category.category)}
                                checked={searchCategory.includes(category.category)}
                            />
                            <span className="custom-checkbox border-purple-500 border-2 after:bg-purple-500"></span>
                            <span>{category.category}</span>
                        </label>)
                    )}
                </div>}
            </div>

            <div className="flex flex-col gap-3">
                <div className="p-3 bg-white">
                    <div className="flex items-center group border border-black/10 focus-within:border-purple-500 px-5 rounded-[5rem] shadow-lg shadow-transparent focus-within:shadow-purple-300/30">
                        <Search className="group-focus-within:opacity-100 opacity-40 group-focus-within:text-purple-500" />
                        <input
                            type="search"
                            name="search"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="flex-1 px-5 py-4 outline-none border-none bg-transparent"
                        />
                    </div>
                </div>
                <div className="bg-white text-left px-10 py-10 max-sm:px-5 border">
                    <div className="flex items-center justify-between">
                        <h2 className="text-xl font-semibold">Products Section</h2>
                        {!!totalProductsResults && <div className="inline gap-1 opacity-60 max-sm:text-sm">
                            <span className="font-medium">{totalProductsResults} </span>
                            <span>Results found in </span>
                            <span className="font-medium">{productsProcessingTime}ms</span>
                        </div>}
                    </div>

                    {productsResults[0].length > 0 && <div className="gap-y-4 sm:grid-cols-[repeat(auto-fill,minmax(15rem,1fr))] grid-cols-[repeat(auto-fill,minmax(12rem,1fr))] grid my-3">
                        {productsResults[0].map((product, index) => (
                            <ProductCard
                                key={index}
                                discount={Number(product.discount_percentage)}
                                discountedPrice={String(Number(product.price))}
                                imageUrl={product.image_url}
                                originalPrice={product.compare_at_price}
                                title={product.title}
                                productUrl={product.product_url}
                            />
                        ))}
                    </div>}
                    {productsResults[0].length === 0 && <div className="grid place-items-center my-3">
                        <div className="animate-pulse">
                            <p className="text-xl font-thin">
                                No Products found.
                            </p>
                        </div>
                    </div>}
                    {Math.floor(totalProductsResults/9) > 1 && <PaginatedList forcePage={productPage ? Number(productPage) - 1 : 0} action={performProductSearch} urlParam="product-page" isNotDispatch pageCount={Math.floor(totalProductsResults/9)} />}
                </div>
                <div className="bg-white text-left px-10 py-10 max-sm:px-5 border">
                    <div className="flex items-center justify-between">
                        <h2 className="text-xl font-semibold">Coupons Section</h2>
                        {!!totalCouponsResults && <div className="inline gap-1 opacity-60 max-sm:text-sm">
                            <span className="font-medium">{totalCouponsResults} </span>
                            <span>Results found in </span>
                            <span className="font-medium">{couponsProcessingTime}ms</span>
                        </div>}
                    </div>

                    {couponsResults[0].length > 0 && <div className="gap-4 sm:grid-cols-[repeat(auto-fill,minmax(15rem,1fr))] grid-cols-[repeat(auto-fill,minmax(12rem,1fr))] grid my-3">
                        {couponsResults[0].map((coupon, index) => (
                            <CouponCard
                                key={index}
                                code={coupon.code}
                                expiry={coupon.expiration_date}
                                grabCarrot={() => {
                                    setModal((prev) => ({ ...prev, isOpen: true, data: { ...coupon } }))
                                    openInNewTab(coupon.redirect_url)
                                }}
                                logo={coupon.logo_image_url}
                                merchant={coupon.merchant_name}
                                title={coupon.name}
                                className="w-full"
                                merchantId={""}
                            />
                        ))}
                    </div>}
                    {couponsResults[0].length === 0 && <div className="grid place-items-center my-3">
                        <div className="animate-pulse">
                            <p className="text-xl font-thin">
                                No Coupons found.
                            </p>
                        </div>
                    </div>}
                    {Math.floor(totalCouponsResults / 9) > 1 && <PaginatedList forcePage={couponPage ? Number(couponPage) - 1 : 0} action={performCouponsearch} urlParam="coupon-page" isNotDispatch pageCount={Math.floor(totalCouponsResults / 9)} />}
                </div>
            </div>
            <AnimatePresence>
                {modal.isOpen && <Modal data={modal.data} handleClickAway={() => setModal((prev) => ({ ...prev, isOpen: false }))} />}
            </AnimatePresence>
        </div>
    )
}