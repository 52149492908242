import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../utilities/important";

export interface Category {
    category: string;
}

export interface Result {
    data: Category[],
    currentPage: number,
    totalPages: number,
    totalItems: number,
};

export const CategortiesThunk = createAsyncThunk(
    "Homepage-top-coupons/thunk",
    async (): Promise<Result> => {
        if (!baseUrl) throw new Error("baseUrl is not defined");

        const url = `${baseUrl}/all/categories`;
        const response = await fetch(url);
        const data = await response.json();
        return data;
    }
)