import { Search } from "lucide-react";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function SearchBar() {
    const [searchInput, setSearchInput] = useState("");
    const location = useNavigate();

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        const term = encodeURI(String(searchInput).trimEnd());
        if(!term) return;

        location(`/search?search=${term}`);
        setSearchInput("");
    }

    return (
        <div className="p-3 bg-white text-left">
            <form onSubmit={handleSubmit} className="flex overflow-hidden items-stretch group border border-black/10 focus-within:border-purple-500 rounded-[5rem] shadow-lg shadow-transparent focus-within:shadow-purple-300/30">
                <input
                    type="search"
                    name="search"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    placeholder="Search"
                    className="flex-1 px-8 py-4 outline-none border-none bg-transparent"
                />
                <button className="px-5 text-white cursor-pointer active:scale-95 select-none place-items-center grid rounded-r-lg">
                    <Search role='button' className="cursor-pointer active:scale-90 text-gray-400" />
                </button>
            </form>
        </div>
    );
};