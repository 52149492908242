import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-use';
import DealsBunnyLogo from './Logo';

const Header: React.FC = () => {
  const { pathname } = useLocation();
  const [currentTab, setCurrentTab] = useState<number | null>(null);

  useEffect(() => {
    if (!pathname) return;
    const  pathParts = pathname.split("/")[1];

    switch (String(pathParts).toLowerCase()) {
      case "":
        setCurrentTab(1);
        break;
      case "products":
        setCurrentTab(2);
        break;
      case "coupons":
        setCurrentTab(3);
        break;
      case "merchants":
        setCurrentTab(4);
        break;
      case "merchant":
        setCurrentTab(4);
        break;
      default:
        setCurrentTab(1);
        break;
    }
  }, [pathname]);
  return (
    <header className='sticky top-0 z-50 bg-white shadow-lg shadow-black/10'>
      {/* Add navigation menu items here */}
      <div className="md:mx-auto flex sm:justify-between flex-wrap items-center py-5 md:px-20 px-5 max-md:w-full">
        <Link to={"/"} className="flex items-center">
          <DealsBunnyLogo className='mr-2 h-20 w-fit text-purple-600' />
        </Link>
        <nav className='min-w-fit max-sm:w-full'>
          <ul className="flex sm:gap-8 gap-3 max-sm:justify-between">
            <li><a href="/" className={clsx(
              currentTab === 1 ? "px-4 text-purple-600 sm:text-base text-sm underline font-semibold underline-offset-4" : "hover:text-purple-600"
            )}>Home</a></li>
            <li><a href="/products" className={clsx(
              currentTab === 2 ? "px-4 text-purple-600 sm:text-base text-sm underline font-semibold underline-offset-4" : "hover:text-purple-600"
            )}>Products</a></li>
            <li><a href="/coupons" className={clsx(
              currentTab === 3 ? "px-4 text-purple-600 sm:text-base text-sm underline font-semibold underline-offset-4" : "hover:text-purple-600"
            )}>Coupons</a></li>
            <li><a href="/merchants" className={clsx(
              currentTab === 4 ? "px-4 text-purple-600 sm:text-base text-sm underline font-semibold underline-offset-4" : "hover:text-purple-600"
            )}>Merchants</a></li>
          </ul>
        </nav>
      </div>
      {/* <div className='px-20 grid grid-cols-[auto_1fr] bg-purple-500'>
        <div className='flex cursor-pointer group gap-3 items-center px-8 py-5 bg-white text-black'>
          <Grid2x2 />
          <span>All Category</span>
          <ChevronRight className='group-hover:rotate-12 group-active:rotate-90' />
        </div>
        <div className='flex items-center gap-2 justify-end py-5'>
          <div className='h-14 w-14 rounded-md grid place-items-center border-2 border-white text-white hover:bg-white hover:text-purple-600 cursor-pointer active:scale-90 shrink-0'>
            <Car size={30} />
          </div>
          <div className='h-14 w-14 rounded-md grid place-items-center border-2 border-white text-white hover:bg-white hover:text-purple-600 cursor-pointer active:scale-90 shrink-0'>
            <Briefcase size={30} />
          </div>
          <div className='h-14 w-14 rounded-md grid place-items-center border-2 border-white text-white hover:bg-white hover:text-purple-600 cursor-pointer active:scale-90 shrink-0'>
            <ShoppingBasket size={30} />
          </div>
          <div className='h-14 w-14 rounded-md grid place-items-center border-2 border-white text-white hover:bg-white hover:text-purple-600 cursor-pointer active:scale-90 shrink-0'>
            <Baby size={30} />
          </div>
          <div className='h-14 w-14 rounded-md grid place-items-center border-2 border-white text-white hover:bg-white hover:text-purple-600 cursor-pointer active:scale-90 shrink-0'>
            <Dog size={30} />
          </div>
          <div className='h-14 w-14 rounded-md grid place-items-center border-2 border-white text-white hover:bg-white hover:text-purple-600 cursor-pointer active:scale-90 shrink-0'>
            <Cat size={30} />
          </div>
          <div className='h-14 w-14 rounded-md grid place-items-center border-2 border-white text-white hover:bg-white hover:text-purple-600 cursor-pointer active:scale-90 shrink-0'>
            <Home size={30} />
          </div>
          <div className='h-14 w-14 rounded-md grid place-items-center border-2 border-white text-white hover:bg-white hover:text-purple-600 cursor-pointer active:scale-90 shrink-0'>
            <Car size={30} />
          </div>
          <div className='h-14 w-14 rounded-md grid place-items-center border-2 border-white text-white hover:bg-white hover:text-purple-600 cursor-pointer active:scale-90 shrink-0'>
            <Briefcase size={30} />
          </div>
          <div className='h-14 w-14 rounded-md grid place-items-center border-2 border-white text-white hover:bg-white hover:text-purple-600 cursor-pointer active:scale-90 shrink-0'>
            <ShoppingBasket size={30} />
          </div>
          <div className='h-14 w-14 rounded-md grid place-items-center border-2 border-white text-white hover:bg-white hover:text-purple-600 cursor-pointer active:scale-90 shrink-0'>
            <Baby size={30} />
          </div>
          <div className='h-14 w-14 rounded-md grid place-items-center border-2 border-white text-white hover:bg-white hover:text-purple-600 cursor-pointer active:scale-90 shrink-0'>
            <Dog size={30} />
          </div>
          <div className='h-14 w-14 rounded-md grid place-items-center border-2 border-white text-white hover:bg-white hover:text-purple-600 cursor-pointer active:scale-90 shrink-0'>
            <Cat size={30} />
          </div>
          <div className='h-14 w-14 rounded-md grid place-items-center border-2 border-white text-white hover:bg-white hover:text-purple-600 cursor-pointer active:scale-90 shrink-0'>
            <Home size={30} />
          </div>
          <div className='h-14 w-14 rounded-md grid place-items-center border-2 border-white text-white hover:bg-white hover:text-purple-600 cursor-pointer active:scale-90 shrink-0'>
            <Car size={30} />
          </div>
          <div className='h-14 w-14 rounded-md grid place-items-center border-2 border-white text-white hover:bg-white hover:text-purple-600 cursor-pointer active:scale-90 shrink-0'>
            <Briefcase size={30} />
          </div>
          <div className='h-14 w-14 rounded-md grid place-items-center border-2 border-white text-white hover:bg-white hover:text-purple-600 cursor-pointer active:scale-90 shrink-0'>
            <ShoppingBasket size={30} />
          </div>
          <div className='h-14 w-14 rounded-md grid place-items-center border-2 border-white text-white hover:bg-white hover:text-purple-600 cursor-pointer active:scale-90 shrink-0'>
            <Baby size={30} />
          </div>
          <div className='h-14 w-14 rounded-md grid place-items-center border-2 border-white text-white hover:bg-white hover:text-purple-600 cursor-pointer active:scale-90 shrink-0'>
            <Dog size={30} />
          </div>
          <div className='h-14 w-14 rounded-md grid place-items-center border-2 border-white text-white hover:bg-white hover:text-purple-600 cursor-pointer active:scale-90 shrink-0'>
            <Cat size={30} />
          </div>
          <div className='h-14 w-14 rounded-md grid place-items-center border-2 border-white text-white hover:bg-white hover:text-purple-600 cursor-pointer active:scale-90 shrink-0'>
            <Home size={30} />
          </div>
        </div>
      </div> */}
    </header>
  );
};

export default Header;