import { createSlice } from "@reduxjs/toolkit";
import { CategortiesThunk, Result } from "../Thunk";
import { RootState } from "../..";

interface initialState {
    loading: 0 | 1 | 2 | 3;
    error?: string;
    result: Result
}

const initialState: initialState = {
    loading: 0,
    error: undefined,
    result: {
        currentPage: 0,
        data: [],
        totalItems: 0,
        totalPages: 0
    }
}

const CategortiesSlice = createSlice({
    name: "Homepage-top-coupons",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(CategortiesThunk.pending,  (state) => {
            state.loading =  1;
            state.error = undefined;
        })

        builder.addCase(CategortiesThunk.rejected,  (state, action) => {
            state.loading = 2;
            state.error =  action.error.message;
        })

        builder.addCase(CategortiesThunk.fulfilled,   (state, action) => {
            state.loading = 3;
            state.result = action.payload;
        });
    }
})

export const $Categorties_loading = (state:RootState) => state.Categorties.loading;
export const $Categorties_error = (state:RootState) => state.Categorties.error;
export const $Categorties_data = (state:RootState) => state.Categorties.result?.data;

export const CategortiesReducer = CategortiesSlice.reducer