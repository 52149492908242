import { Tag } from "lucide-react";
import DealImage from "../../components/DealImage";
import { Link } from "react-router-dom";

export interface ProductCardProps {
    title: string;
    originalPrice: string;
    discountedPrice: string;
    discount: number;
    imageUrl: string;
    productUrl: string;
}

export const ProductCard: React.FC<ProductCardProps> = ({ title, originalPrice, discountedPrice, discount, imageUrl, productUrl }) => (
    <div className="flex-shrink-0 bg-white rounded-lg shadow-md overflow-hidden border border-gray-200 hover:border-purple-500 mr-4 h-full">
        <DealImage 
            src={imageUrl} 
            alt={title} 
            className="w-full h-48 object-cover"
        />
        <div className="p-4 border-t">
            <h4 className="font-semibold leading-5 line-clamp-2 h-12 mb-2 text-gray-800">{title}</h4>
            <div className="flex justify-between items-center mb-2">
                <div>
                    <span className="text-gray-500 line-through text-sm">${originalPrice}</span>
                    <span className="text-purple-600 font-bold ml-2">${discountedPrice}</span>
                </div>
                <span className="bg-green-100 text-green-800 text-xs font-semibold px-2 py-1 rounded">
                    {discount}% OFF
                </span>
            </div>
            <Link to={productUrl} target="_blank" className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-full flex items-center justify-center transition duration-300">
                <Tag className="mr-2" />
                View Deal
            </Link>
        </div>
    </div>
);