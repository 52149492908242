import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../utilities/important";
import { Data } from "../../../utilities/Interfaces/Merchants";

export const CategoryMerchantsThunk = createAsyncThunk(
    "Merchants/category-thunk",
    async ({ category }: { category: string }): Promise<Data> => {
        if (!baseUrl) throw new Error("baseUrl is not defined");

        const url = `${baseUrl}/category/${category}/merchants`;
        const response = await fetch(url);
        const data = await response.json();
        return data;
    }
)


export const CategoryNextMerchantsThunk = createAsyncThunk(
    "Merchants/next-Merchants-thunk",
    async ({ page, category }: { page: number, category: string }): Promise<Data> => {
        if (!baseUrl) throw new Error("baseUrl is not defined");
        const url = `${baseUrl}/category/${category}/merchants?page=${page}`;
        const response = await fetch(url);
        const data = await response.json();
        return data;
    }
)