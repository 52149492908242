import { useNavigate, useParams } from 'react-router-dom';
// import '../styles/MerchantPage.css';
import DealImage from '../components/DealImage';
import { Link } from 'react-router-dom';
import { Link2 } from 'lucide-react';
import Carousel from '../components/Carousel';
import { CouponCard } from './sub components/CouponCard';
import { Merchant } from '../utilities/Interfaces/Homepage-top-coupons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../Store';
import { useEffect, useState } from 'react';
import { MerchantThunk } from '../Store/Marchant/Thunk';
import { $Merchant_data, $Merchant_loading } from '../Store/Marchant/Slice';
import { MerchantCouponsThunk } from '../Store/Marchant/coupons/Thunk';
import { $MerchantCoupons_data, $MerchantCoupons_loading } from '../Store/Marchant/coupons/Slice';
import { MerchantProductsThunk } from '../Store/Marchant/products/Thunk';
import { $MerchantProducts_data, $MerchantProducts_loading } from '../Store/Marchant/products/Slice';
import { ProductCard } from './sub components/ProductCard';
import { AnimatePresence } from 'framer-motion';
import Modal from '../components/Modal';
import { Helmet } from 'react-helmet';
import { openInNewTab } from '../utilities/important';
import SearchBar from './sub components/SearchBar';

const MerchantPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [modal, setModal] = useState<{
        isOpen: boolean,
        data: Merchant,
    }>({
        isOpen: false,
        data: {} as Merchant
    });

    const navigate = useNavigate();


    const dispatch = useDispatch<AppDispatch>();
    const Merchant_loading = useSelector($Merchant_loading);
    const Merchant_data = useSelector($Merchant_data);
    
    const MerchantCoupons_loading = useSelector($MerchantCoupons_loading);
    const MerchantCoupons_data = useSelector($MerchantCoupons_data  );

    const MerchantProducts_loading = useSelector($MerchantProducts_loading);
    const MerchantProducts_data = useSelector($MerchantProducts_data);

    const isLoaded = Merchant_loading === 3 && Merchant_data && !Object.keys(Merchant_data).includes("message");

    useEffect(() => {
        if(Merchant_loading === 3 && !isLoaded) {
            navigate(-1);
        }
    }, [Merchant_loading, isLoaded, Merchant_data]);

    useEffect(() => {
        dispatch(MerchantThunk(Number(id)));
        dispatch(MerchantCouponsThunk(Number(id)))
        dispatch(MerchantProductsThunk(Number(id)))
    }, [dispatch]);

    return (
        <div className='bg-black/5 min-h-screen text-left lg:px-20 md:px-10 px-5 py-3 flex flex-col gap-3'>
            {Merchant_data && <Helmet>
                <title>
                    {`${Merchant_data?.mcp?.Merchant_Info?.merchantName ?? "Merchant"} Coupons & Deals ${new Date().getFullYear()} | Save on Top Products with Discounts`}
                </title>

                <meta name="description" content={`${Merchant_data?.mcp?.about?.content ?? Merchant_data?.mcp?.Merchant_Info?.businessType ?? "Save on a variety of top products with exclusive discounts and deals"}`} />

                <meta property="og:title" content={`${Merchant_data?.mcp?.Merchant_Info?.Merchant_Name ?? "Generic Merchant"} Coupons & Deals ${new Date().getFullYear()} | Save on Top Products with Discounts`} />

                <meta property="og:description" content={`${Merchant_data?.mcp?.about?.content ?? Merchant_data?.mcp?.Merchant_Info?.businessType ?? "Find the best discounts and deals available online"}`} />

                <meta property="og:image" content={Merchant_data?.logo_image_url ?? "default-logo-url.jpg"} />

                <meta name="twitter:card" content="summary_large_image" />

                <meta name="twitter:title" content={`${Merchant_data?.mcp?.Merchant_Info?.Merchant_Name ?? "Generic Merchant"} Coupons & Deals ${new Date().getFullYear()} | Save on Top Products with Discounts`} />

                <meta name="twitter:description" content={`${Merchant_data?.mcp?.about?.content ?? Merchant_data?.mcp?.Merchant_Info?.businessType ?? "Discover amazing savings and exclusive offers on top products"}`} />

                <meta name="twitter:image" content={Merchant_data?.logo_image_url ?? "default-logo-url.jpg"} />

                <meta name="keywords" content={`${`${Merchant_data?.mcp?.seoKeywords?.join(', ')}`}, ${Merchant_data?.mcp?.Merchant_Info?.merchantName},${"coupons, deals, discounts, savings"}`} />

            </Helmet>}
            {Merchant_data?.mcp?.Merchant_Info && <div className='bg-white px-6 py-3 flex gap-2'>
                {Merchant_data?.mcp?.Merchant_Info.Level_1 && <Link className='text-blue-600 capitalize hover:text-purple-600 hover:scale-105 active:scale-95' to={`/products/${Merchant_data?.mcp.Merchant_Info.Level_1}`}>{Merchant_data?.mcp.Merchant_Info.Level_1}</Link>}/
                {Merchant_data?.mcp?.Merchant_Info.Level_2 && <Link className='text-blue-600 capitalize hover:text-purple-600 hover:scale-105 active:scale-95' to={`/products/${Merchant_data?.mcp.Merchant_Info.Level_2}`}>{Merchant_data?.mcp.Merchant_Info.Level_2}</Link>}/
                {Merchant_data?.mcp?.Merchant_Info && <span className='capitalize'> {Merchant_data?.mcp.Merchant_Info.Merchant_Name}</span>}
            </div>}
            <div className='grid sm:grid-cols-[auto_1fr] gap-3'>
                <div className='max-sm:hidden flex flex-col gap-3 sm:w-[20rem]'>
                    {!isLoaded && <div className="bg-white border h-fit py-10 text-left xl:block hidden">
                        <div className='h-44 grid place-items-center overflow-hidden max-w-80 px-10 relative border-b'>
                            <DealImage
                                src='h'
                                alt='Loading Image'
                                className='h-44 absolute mix-blend-multiply top-0 object-cover scale-150 opacity-10 animate-pulse'
                            />
                            <DealImage
                                src='h'
                                alt='Loading Image'
                                className='h-44 px-6 animate-pulse'
                            />
                        </div>
                        <div className='px-6 py-3'>
                            <h2 className='text-xl font-semibold py-2 bg-black/10 rounded-3xl animate-pulse'></h2>
                            <div className='flex items-center gap-12 justify-between group'>
                                <div className='mt-2 py-1 bg-black/10 rounded-3xl animate-pulse flex-[3]'></div>
                                <div className='mt-2 py-1 bg-black/10 rounded-3xl animate-pulse px-5'></div>
                            </div>
                            <h2 className='mt-4 text-xl font-semibold py-2 bg-black/10 rounded-3xl animate-pulse'></h2>
                        </div>
                    </div>}
                    {isLoaded && <div className="bg-white border h-fit py-10 text-left xl:block hidden">
                        <div className='h-44 grid place-items-center overflow-hidden max-w-80 px-10 relative border-b'>
                            <DealImage
                                src={Merchant_data?.logo_image_url}
                                alt={Merchant_data?.name}
                                className='h-44 absolute mix-blend-multiply top-0 object-cover scale-150 blur opacity-25'
                            />
                            <DealImage
                                src={Merchant_data?.logo_image_url}
                                alt={Merchant_data?.name}
                                className='max-h-44 object-cover w-[90%] mix-blend-multiply px-6'
                            />
                        </div>
                        <div className='px-6 py-3'>
                            <h2 className='text-xl font-semibold'>{Merchant_data?.merchant_name}</h2>
                            <Link target='_blank' to={Merchant_data?.url ?? ""} className='text-blue-600 active:text-purple-500 flex items-center gap-2 justify-between group'><p className='max-w-[90%] truncate'>{Merchant_data?.url}</p> <Link2 className='group-hover:rotate-6' /></Link>
                            {Merchant_data?.mcp?.Merchant_Info && <p className='font-semibold opacity-60 mt-3'>{Merchant_data?.mcp.Merchant_Info.parentCompany}</p>}
                        </div>
                    </div>}
                    {!isLoaded && <div className='bg-white py-10 px-6'>
                        <h2 className='text-xl font-semibold py-2 bg-black/10 rounded-3xl animate-pulse'></h2>
                        <div className='text-sm my-3 grid gap-2'>
                            <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                            <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                            <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                            <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                            <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                            <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                            <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                        </div>
                        <div className='flex items-center gap-12 justify-between group'>
                            <div className='mt-2 py-1 bg-black/10 rounded-3xl animate-pulse flex-[3]'></div>
                            <div className='mt-2 py-1 bg-black/10 rounded-3xl animate-pulse px-5'></div>
                        </div>
                    </div>}
                    {isLoaded && Merchant_data?.mcp?.Merchant_Info &&  <div className='bg-white py-3 px-6'>
                        <p className='my-3'>
                            <span className='font-semibold'>Founded:</span> {Merchant_data?.mcp.Merchant_Info.foundedYear}
                        </p>
                        <p className='my-3'>
                            <span className='font-semibold'>Business Type:</span> {Merchant_data?.mcp.Merchant_Info.businessType}
                        </p>
                        <p className='my-3'>
                            <span className='font-semibold'>Website:</span> <Link to={Merchant_data?.mcp.Merchant_Info.URL ?? ""} className="text-blue-500">{Merchant_data?.mcp.Merchant_Info.URL}</Link>
                        </p>
                        <p className='my-3'>
                            <span className='font-semibold'>Area Served:</span> {Merchant_data?.mcp.Merchant_Info.areaServed}
                        </p>
                    </div>}
                    {isLoaded && Merchant_data?.mcp.about &&  <div className='bg-white py-10 px-6'>
                        <h2 className='text-lg font-semibold leading-5'>{Merchant_data?.mcp.about.title}</h2>
                        <p className='text-sm my-3'>
                            {Merchant_data?.mcp.about.content}
                        </p>
                        <Link target='_blank' to={Merchant_data?.mcp.about.url ?? ""} className='text-blue-600 active:text-purple-500 flex items-center gap-2 justify-between group'><p className='max-w-[90%] truncate'>{Merchant_data?.mcp.about.url}</p> <Link2 className='group-hover:rotate-6' /></Link>
                    </div>}
                </div>
                <div className='flex flex-col gap-3 w-full relative overflow-hidden'>
                    <SearchBar />
                    {Array.isArray(MerchantCoupons_data) && <div className='bg-white p-6'>
                        <div className='flex items-center justify-between mb-6 h-fit'>
                            <h3 className='text-xl font-bold'>Top Coupons</h3>
                            <Link to={"./coupons"} className='px-8 py-3 border border-purple-500 rounded-3xl text-center text-purple-500 hover:text-white hover:bg-purple-500 hover:border-transparent active:scale-90 active:opacity-70 cursor-pointer select-none'>View All</Link>
                        </div>
                        <Carousel start={3}>
                            {MerchantCoupons_loading === 3 && MerchantCoupons_data.map((deal, index) => (
                                <CouponCard
                                    key={index}
                                    code={deal.code}
                                    merchant={deal.merchant_name}
                                    expiry={deal.expiration_date}
                                    logo={deal.logo_image_url}
                                    title={deal.name}
                                    grabCarrot={() => {
                                        setModal((prev) => ({ ...prev, isOpen: true, data: deal as Merchant}))
                                        navigator.clipboard.writeText(deal.code);
                                        openInNewTab(deal.redirect_url)
                                    }}
                                />
                            ))}
                            {MerchantCoupons_loading !== 3 && new Array(10).fill("").map((_, $) => (
                                <div key={$} className="h-fit max-w-[98%] animate-pulse border shadow-lg rounded-md">
                                    <div className="border-b">
                                        <DealImage
                                            src=""
                                            alt=""
                                            className="w-full h-48 object-cover"
                                        />
                                    </div>
                                    <div className="p-4 grid gap-1">
                                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl">{_}</div>
                                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                                    </div>
                                    <div className="px-4 pb-4 grid gap-1">
                                        <div className="p-2 bg-purple-500 animate-pulse rounded-2xl text-center text-white">Loading</div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </div>}
                    {Array.isArray(MerchantProducts_data) && <div className='bg-white p-6'>
                        <div className='flex items-center justify-between mb-6'>
                            <h3 className='text-xl font-bold'>Top Products</h3>
                            <Link to={"./products"} className='px-8 py-3 border border-purple-500 rounded-3xl text-center text-purple-500 hover:text-white hover:bg-purple-500 hover:border-transparent active:scale-90 active:opacity-70 cursor-pointer select-none'>View All</Link>
                        </div>
                        {<Carousel start={3}>
                            {MerchantProducts_loading === 3 && MerchantProducts_data.map((product, index) => (
                                <ProductCard
                                    key={index}
                                    discount={Number(product.discount_percentage)}
                                    discountedPrice={String(Number(product.price))}
                                    imageUrl={product.image_url ?? ""}
                                    originalPrice={product.compare_at_price}
                                    title={product.title}
                                    productUrl={product.product_url}
                                />
                            ))}
                            {MerchantProducts_loading !== 3 && new Array(10).fill("").map((_, $) => (
                                <div key={$} className="h-fit max-w-[98%] animate-pulse border shadow-lg rounded-md">
                                    <div className="border-b">
                                        <DealImage
                                            src=""
                                            alt=""
                                            className="w-full h-48 object-cover"
                                        />
                                    </div>
                                    <div className="p-4 grid gap-1">
                                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl">{_}</div>
                                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                                    </div>
                                    <div className="px-4 pb-4 grid gap-1">
                                        <div className="p-2 bg-purple-500 animate-pulse rounded-2xl text-center text-white">Loading</div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>}
                    </div>}
                    {!isLoaded && <div className='grid lg:grid-cols-2 grid-cols-1 gap-3'>
                        <div className='p-6 bg-white'>
                            <h2 className='text-xl font-semibold py-2 bg-purple-800/10 rounded-3xl animate-pulse'></h2>
                            <div className='text-sm my-3 grid gap-2'>
                                <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                                <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                                <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                                <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                                <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                                <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                                <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                            </div>
                        </div>
                        <div className='p-6 bg-white'>
                            <h2 className='text-xl font-semibold py-2 bg-purple-800/10 rounded-3xl animate-pulse'></h2>
                            <div className='text-sm my-3 grid gap-2'>
                                <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                                <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                                <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                                <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                                <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                                <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                                <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                            </div>
                        </div>
                    </div>}

                    <div className='hidden max-sm:flex flex-col gap-3 sm:w-[20rem]'>
                        {!isLoaded && <div className="bg-white border h-fit py-10 text-left xl:block hidden">
                            <div className='h-44 grid place-items-center overflow-hidden max-w-80 px-10 relative border-b'>
                                <DealImage
                                    src='h'
                                    alt='Loading Image'
                                    className='h-44 absolute mix-blend-multiply top-0 object-cover scale-150 opacity-10 animate-pulse'
                                />
                                <DealImage
                                    src='h'
                                    alt='Loading Image'
                                    className='h-44 px-6 animate-pulse'
                                />
                            </div>
                            <div className='px-6 py-3'>
                                <h2 className='text-xl font-semibold py-2 bg-black/10 rounded-3xl animate-pulse'></h2>
                                <div className='flex items-center gap-12 justify-between group'>
                                    <div className='mt-2 py-1 bg-black/10 rounded-3xl animate-pulse flex-[3]'></div>
                                    <div className='mt-2 py-1 bg-black/10 rounded-3xl animate-pulse px-5'></div>
                                </div>
                                <h2 className='mt-4 text-xl font-semibold py-2 bg-black/10 rounded-3xl animate-pulse'></h2>
                            </div>
                        </div>}
                        {isLoaded && <div className="bg-white border h-fit py-10 text-left xl:block hidden">
                            <div className='h-44 grid place-items-center overflow-hidden max-w-80 px-10 relative border-b'>
                                <DealImage
                                    src={Merchant_data?.logo_image_url}
                                    alt={Merchant_data?.name}
                                    className='h-44 absolute mix-blend-multiply top-0 object-cover scale-150 blur opacity-25'
                                />
                                <DealImage
                                    src={Merchant_data?.logo_image_url}
                                    alt={Merchant_data?.name}
                                    className='max-h-44 object-cover w-[90%] mix-blend-multiply px-6'
                                />
                            </div>
                            <div className='px-6 py-3'>
                                <h2 className='text-xl font-semibold'>{Merchant_data?.merchant_name}</h2>
                                <Link target='_blank' to={Merchant_data?.url ?? ""} className='text-blue-600 active:text-purple-500 flex items-center gap-2 justify-between group'><p className='max-w-[90%] truncate'>{Merchant_data?.url}</p> <Link2 className='group-hover:rotate-6' /></Link>
                                {Merchant_data?.mcp?.Merchant_Info && <p className='font-semibold opacity-60 mt-3'>{Merchant_data?.mcp.Merchant_Info.parentCompany}</p>}
                            </div>
                        </div>}
                        {!isLoaded && <div className='bg-white py-10 px-6'>
                            <h2 className='text-xl font-semibold py-2 bg-black/10 rounded-3xl animate-pulse'></h2>
                            <div className='text-sm my-3 grid gap-2'>
                                <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                                <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                                <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                                <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                                <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                                <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                                <p className='py-1 bg-black/10 rounded-3xl animate-pulse'></p>
                            </div>
                            <div className='flex items-center gap-12 justify-between group'>
                                <div className='mt-2 py-1 bg-black/10 rounded-3xl animate-pulse flex-[3]'></div>
                                <div className='mt-2 py-1 bg-black/10 rounded-3xl animate-pulse px-5'></div>
                            </div>
                        </div>}
                        {isLoaded && Merchant_data?.mcp?.Merchant_Info && <div className='bg-white py-3 px-6'>
                            <p className='my-3'>
                                <span className='font-semibold'>Founded:</span> {Merchant_data?.mcp.Merchant_Info.foundedYear}
                            </p>
                            <p className='my-3'>
                                <span className='font-semibold'>Business Type:</span> {Merchant_data?.mcp.Merchant_Info.businessType}
                            </p>
                            <p className='my-3'>
                                <span className='font-semibold'>Website:</span> <Link to={Merchant_data?.mcp.Merchant_Info.URL ?? ""} className="text-blue-500">{Merchant_data?.mcp.Merchant_Info.URL}</Link>
                            </p>
                            <p className='my-3'>
                                <span className='font-semibold'>Area Served:</span> {Merchant_data?.mcp.Merchant_Info.areaServed}
                            </p>
                        </div>}
                        {isLoaded && Merchant_data?.mcp.about && <div className='bg-white py-10 px-6'>
                            <h2 className='text-lg font-semibold leading-5'>{Merchant_data?.mcp.about.title}</h2>
                            <p className='text-sm my-3'>
                                {Merchant_data?.mcp.about.content}
                            </p>
                            <Link target='_blank' to={Merchant_data?.mcp.about.url ?? ""} className='text-blue-600 active:text-purple-500 flex items-center gap-2 justify-between group'><p className='max-w-[90%] truncate'>{Merchant_data?.mcp.about.url}</p> <Link2 className='group-hover:rotate-6' /></Link>
                        </div>}
                    </div>
                    {isLoaded && Merchant_data?.mcp.loyalty && <div>
                        <div className='p-6 bg-white mb-3'>
                            <h3 className='text-xl font-semibold mb-3 text-purple-950'>
                                {Merchant_data?.mcp.loyalty.title}
                            </h3>
                            <p>
                                {Merchant_data?.mcp.loyalty.content}
                            </p>
                            <Link target='_blank' to={Merchant_data?.mcp.loyalty.url ?? ""} className='mt-3 text-blue-600 active:text-purple-500 flex items-center gap-2 justify-between group'><p className='max-w-[90%] truncate'>{Merchant_data?.mcp.loyalty.url}</p> <Link2 className='group-hover:rotate-6' /></Link>
                        </div>
                        <div className='mt-5 mb-3'>
                            <h3 className='text-3xl font-bold mb-3 text-purple-950'>Discounts</h3>
                            <div className='grid lg:grid-cols-2 grid-cols-1 gap-3'>
                                <div className='p-6 bg-white'>
                                    <h3 className='text-xl font-semibold mb-3 text-purple-950'>
                                        {Merchant_data?.mcp.discounts.title}
                                    </h3>
                                    <p>
                                        {Merchant_data?.mcp.discounts.content}
                                    </p>
                                    <Link target='_blank' to={Merchant_data?.mcp.discounts.url ?? ""} className='mt-3 text-blue-600 active:text-purple-500 flex items-center gap-2 justify-between group'><p className='max-w-[90%] truncate'>{Merchant_data?.mcp.discounts.url}</p> <Link2 className='group-hover:rotate-6' /></Link>
                                </div>
                                <div className='p-6 bg-white'>
                                    <h3 className='text-xl font-semibold mb-3 text-purple-950'>
                                        {Merchant_data?.mcp.studentDiscount.title}
                                    </h3>
                                    <p>
                                        {Merchant_data?.mcp.studentDiscount.content}
                                    </p>
                                    <Link target='_blank' to={Merchant_data?.mcp.studentDiscount.url ?? ""} className='mt-3 text-blue-600 active:text-purple-500 flex items-center gap-2 justify-between group'><p className='max-w-[90%] truncate'>{Merchant_data?.mcp.studentDiscount.url}</p> <Link2 className='group-hover:rotate-6' /></Link>
                                </div>
                                <div className='p-6 bg-white'>
                                    <h3 className='text-xl font-semibold mb-3 text-purple-950'>
                                        {Merchant_data?.mcp.militaryDiscount.title}
                                    </h3>
                                    <p>
                                        {Merchant_data?.mcp.militaryDiscount.content}
                                    </p>
                                    <Link target='_blank' to={Merchant_data?.mcp.militaryDiscount.url ?? ""} className='mt-3 text-blue-600 active:text-purple-500 flex items-center gap-2 justify-between group'><p className='max-w-[90%] truncate'>{Merchant_data?.mcp.militaryDiscount.url}</p> <Link2 className='group-hover:rotate-6' /></Link>
                                </div>
                            </div>
                        </div>
            
                        <div className='mt-5 mb-3'>
                            <h3 className='text-3xl font-bold mb-3 text-purple-950'>Discover</h3>
                            <div className='grid lg:grid-cols-2 grid-cols-1 gap-3'>
                               {Merchant_data?.mcp.couponPage &&  <div className='p-6 bg-white'>
                                    <h3 className='text-xl font-semibold mb-3 text-purple-950'>
                                        {Merchant_data?.mcp.couponPage.title}
                                    </h3>
                                    <p>
                                        {Merchant_data?.mcp.couponPage.content}
                                    </p>
                                    <Link target='_blank' to={Merchant_data?.mcp.couponPage.url ?? ""} className='mt-3 text-blue-600 active:text-purple-500 flex items-center gap-2 justify-between group'><p className='max-w-[90%] truncate'>{Merchant_data?.mcp.couponPage.url}</p> <Link2 className='group-hover:rotate-6' /></Link>
                                </div>}
                                {Merchant_data?.mcp.registryDeals && <div className='p-6 bg-white'>
                                    <h3 className='text-xl font-semibold mb-3 text-purple-950'>
                                        {Merchant_data?.mcp.registryDeals.title}
                                    </h3>
                                    <p>
                                        {Merchant_data?.mcp.registryDeals.content}
                                    </p>
                                    <Link target='_blank' to={Merchant_data?.mcp.registryDeals.url ?? ""} className='mt-3 text-blue-600 active:text-purple-500 flex items-center gap-2 justify-between group'><p className='max-w-[90%] truncate'>{Merchant_data?.mcp.registryDeals.url}</p> <Link2 className='group-hover:rotate-6' /></Link>
                                </div>}
                                {Merchant_data?.mcp.seasonalSavings && <div className='p-6 bg-white'>
                                    <h3 className='text-xl font-semibold mb-3 text-purple-950'>
                                        {Merchant_data?.mcp.seasonalSavings.title}
                                    </h3>
                                    <p>
                                        {Merchant_data?.mcp.seasonalSavings.content}
                                    </p>
                                    <Link target='_blank' to={Merchant_data?.mcp.seasonalSavings.url ?? ""} className='mt-3 text-blue-600 active:text-purple-500 flex items-center gap-2 justify-between group'><p className='max-w-[90%] truncate'>{Merchant_data?.mcp.seasonalSavings.url}</p> <Link2 className='group-hover:rotate-6' /></Link>
                                </div>}
                                {Merchant_data?.mcp.HowtoSaveTheMost && <div className='p-6 bg-white'>
                                    <h3 className='text-xl font-semibold mb-3 text-purple-950'>
                                        {Merchant_data?.mcp.HowtoSaveTheMost.title}
                                    </h3>
                                    <p>
                                        {Merchant_data?.mcp.HowtoSaveTheMost.content}
                                    </p>
                                    <Link target='_blank' to={Merchant_data?.mcp.HowtoSaveTheMost.url ?? ""} className='mt-3 text-blue-600 active:text-purple-500 flex items-center gap-2 justify-between group'><p className='max-w-[90%] truncate'>{Merchant_data?.mcp.HowtoSaveTheMost.url}</p> <Link2 className='group-hover:rotate-6' /></Link>
                                </div>}
                            </div>
                        </div>
            
                        <div className='mt-5 mb-3'>
                            <h3 className='text-3xl font-bold mb-3 text-purple-950'>Policies</h3>
                            <div className='grid lg:grid-cols-2 grid-cols-1 gap-3'>
                                {Merchant_data?.mcp.returnPolicy && <div className='p-6 bg-white'>
                                    <h3 className='text-xl font-semibold mb-3 text-purple-950'>
                                        {Merchant_data?.mcp.returnPolicy.title}
                                    </h3>
                                    <p>
                                        {Merchant_data?.mcp.returnPolicy.content}
                                    </p>
                                    <Link target='_blank' to={Merchant_data?.mcp.returnPolicy.url ?? ""} className='mt-3 text-blue-600 active:text-purple-500 flex items-center gap-2 justify-between group'><p className='max-w-[90%] truncate'>{Merchant_data?.mcp.returnPolicy.url}</p> <Link2 className='group-hover:rotate-6' /></Link>
                                </div>}
                                {Merchant_data?.mcp.freeShippingPolicy && <div className='p-6 bg-white'>
                                    <h3 className='text-xl font-semibold mb-3 text-purple-950'>
                                        {Merchant_data?.mcp.freeShippingPolicy.title}
                                    </h3>
                                    <p>
                                        {Merchant_data?.mcp.freeShippingPolicy.content}
                                    </p>
                                    <Link target='_blank' to={Merchant_data?.mcp.freeShippingPolicy.url ?? ""} className='mt-3 text-blue-600 active:text-purple-500 flex items-center gap-2 justify-between group'><p className='max-w-[90%] truncate'>{Merchant_data?.mcp.freeShippingPolicy.url}</p> <Link2 className='group-hover:rotate-6' /></Link>
                                </div>}
                                {Merchant_data?.mcp.creditCard && <div className='p-6 bg-white'>
                                    <h3 className='text-xl font-semibold mb-3 text-purple-950'>
                                        {Merchant_data?.mcp.creditCard.title}
                                    </h3>
                                    <p>
                                        {Merchant_data?.mcp.creditCard.content}
                                    </p>
                                    <Link target='_blank' to={Merchant_data?.mcp.creditCard.url ?? ""} className='mt-3 text-blue-600 active:text-purple-500 flex items-center gap-2 justify-between group'><p className='max-w-[90%] truncate'>{Merchant_data?.mcp.creditCard.url}</p> <Link2 className='group-hover:rotate-6' /></Link>
                                </div>}
                            </div>
                        </div>
                    </div>}
                </div>
                <AnimatePresence>
                    {modal.isOpen && <Modal data={modal.data} handleClickAway={() => setModal((prev) => ({ ...prev, isOpen: false }))} />}
                </AnimatePresence>
            </div>
        </div>
    );
}

export default MerchantPage;
