import { createSlice } from "@reduxjs/toolkit";
import { AllMerchantsThunk, NextMerchantsThunk, Result } from "../Thunk";
import { RootState } from "../..";

interface InitialState {
    loading: 0 | 1 | 2 | 3;
    error?: string;
    result: Result
}

const initialState: InitialState = {
    loading: 0,
    error: undefined,
    result: {
        currentPage: 0,
        data: [],
        totalItems: 0,
        totalPages: 0
    }
}

const MerchantsSlice = createSlice({
    name: "Merchants",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(AllMerchantsThunk.pending,  (state) => {
            state.loading =  1;
            state.error = undefined;
        })

        builder.addCase(AllMerchantsThunk.rejected,  (state, action) => {
            state.loading = 2;
            state.error =  action.error.message;
        })

        builder.addCase(AllMerchantsThunk.fulfilled,   (state, action) => {
            state.loading = 3;
            state.result = action.payload;
        });
        builder.addCase(NextMerchantsThunk.pending,  (state) => {
            state.loading =  1;
            state.error = undefined;
        })

        builder.addCase(NextMerchantsThunk.rejected,  (state, action) => {
            state.loading = 2;
            state.error =  action.error.message;
        })

        builder.addCase(NextMerchantsThunk.fulfilled,   (state, action) => {
            state.loading = 3;
            state.result = action.payload;
        });
    }
})

export const $Merchants_loading = (state:RootState) => state.Merchants.loading;
export const $Merchants_error = (state:RootState) => state.Merchants.error;
export const $Merchants_data = (state:RootState) => state.Merchants.result?.data;
export const $Merchants_data_totalPages = (state:RootState) => state.Merchants.result?.totalPages;
export const $Merchants_data_totalMerchants = (state:RootState) => state.Merchants.result?.totalItems;

export const MerchantsReducer = MerchantsSlice.reducer