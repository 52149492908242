import clsx from 'clsx';
import { ShoppingBag, Clock } from 'lucide-react';
import { Link } from 'react-router-dom';
import DealImage from '../../components/DealImage';

export interface CouponCardProps {
    title: string;
    merchant: string;
    code: string;
    expiry: string;
    className?: string;
    logo?: string;
    merchantId?: string;
    grabCarrot: () => void
}

export const CouponCard: React.FC<CouponCardProps> = ({ title, merchant, grabCarrot, expiry, className: extraClass, merchantId, logo }) => {
    const expirationData = new Date(expiry);
    const isExpired = expirationData < new Date();

    const expDate = isExpired ? new Date() : expirationData;
    return (
        <div className={clsx(
            "flex-shrink-0 bg-white mx-auto rounded-lg shadow-md overflow-hidden border border-gray-200 hover:border-purple-500 mr-4",
            extraClass
        )}>
            <div className="bg-purple-50 grid place-items-center relative">
                <DealImage
                    src={String(logo)}
                    alt={title}
                    className='h-32 w-full object-cover opacity-10 blur'
                />
                <DealImage
                    src={String(logo)}
                    alt={title}
                    className='max-h-16 w-[80%] object-cover mix-blend-multiply absolute'
                />
            </div>
            <p className="text-center py-2 px-6 text-xl font-medium text-purple-500">{title}</p>
            <h4 className="text-base font-semibold mb-2 text-center opacity-60">{merchant}</h4>
            <div className="px-4">
                <div className="flex items-center mb-4">
                    <Clock className="text-gray-400 mr-2" />
                    <p className="text-sm text-gray-500">Hops away on: {expDate.toLocaleDateString()}</p>
                </div>
                {merchantId && <div className="flex items-center mb-4">
                    <Link to={`/merchant/${merchantId}`} className="text-sm text-gray-500">More {merchant} coupons</Link>
                </div>}
                <button onClick={grabCarrot} className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 mt-2 mb-4 px-4 rounded-full flex items-center justify-center transition duration-300">
                    <ShoppingBag className="mr-2" />
                    Grab This Carrot!
                </button>
            </div>
        </div>
    )
};