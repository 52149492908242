import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../Store";
import { useEffect, useState } from "react";
import DealImage from "../components/DealImage";
import { Link, useParams} from "react-router-dom";
import { ChevronRight } from "lucide-react";
import { $Categorties_data, $Categorties_loading } from "../Store/Categories/Slice";
import { CategortiesThunk } from "../Store/Categories/Thunk";
import PaginatedList from "./sub components/Pagination";
import { CouponCard } from "./sub components/CouponCard";
import { $CouponCategory_data, $CouponCategory_data_pagination, $CouponCategory_error, $CouponCategory_loading } from "../Store/CouponsCategory/Slice";
import { CategoryCouponsThunk, CategoryNextCouponsThunk } from "../Store/CouponsCategory/Thunk";
import clsx from "clsx";
import { openInNewTab } from "../utilities/important";
import { Helmet } from "react-helmet";
import { CouponCat } from "../utilities/Interfaces/Coupons";
import { AnimatePresence } from "framer-motion";
import Modal from "../components/Modal";
import SearchBar from "./sub components/SearchBar";

export default function CouponsPageCategory() {
    const { id } = useParams<{ id: string }>();

    const dispatch = useDispatch<AppDispatch>();
    const queryParams = new URLSearchParams(window.location.search);
    const page = Number(queryParams.get('page')) ?? 1;

    const [modal, setModal] = useState<{
        isOpen: boolean,
        data: CouponCat,
    }>({
        isOpen: false,
        data: {} as CouponCat
    });

    const Categorties_loading = useSelector($Categorties_loading);  
    const Categorties_data = useSelector($Categorties_data);
    
    const [hamburgerMenu, setHamburgerMenu] = useState(false);

    const CouponCategory_loading = useSelector($CouponCategory_loading);  
    const CouponCategory_error = useSelector($CouponCategory_error);  
    const CouponCategory_data = useSelector($CouponCategory_data);
    const CouponCategory_data_pagination = useSelector($CouponCategory_data_pagination);

    useEffect(() => {
        if (!id) return;

        dispatch(CategortiesThunk());
        if(page) {
            dispatch(CategoryNextCouponsThunk({ page: Number(page), category: id }));
            return;
        }
        
        dispatch(CategoryCouponsThunk({category: id}));
    }, [dispatch, page, id]);

    const getMetaDescription = () => {
        const totalDeals = CouponCategory_data_pagination.totalCoupons;
        const categories = [Categorties_data.map((e)=> e.category)];
        const maxDiscount = 90;

        return `Find ${totalDeals} amazing deals across ${categories.length} categories with discounts up to ${maxDiscount}% off. Browse our curated collection of coupons and special offers on ${categories.join(', ')}.`;
    };

    const getTitle = () => {
        if (id) {
            return `Coupons for "${id}" - Best Coupons & Discounts`;
        }
        return 'Best Deals, Coupons & Discounts | Save Up To 70% Off';
    };

    const getCanonicalUrl = () => {
        const baseUrl = `${window.location.origin}/products`;
        if (id) {
            return `${baseUrl}/${id}`
        };
        return baseUrl;
    };


    return (
        <>
            {Categorties_loading === 3 && CouponCategory_loading === 3 && <Helmet>
                {/* Basic Meta Tags */}
                <title>{getTitle()}</title>
                <meta name="description" content={getMetaDescription()} />
                <link rel="canonical" href={getCanonicalUrl()} />

                {/* OpenGraph Meta Tags */}
                <meta property="og:title" content={getTitle()} />
                <meta property="og:description" content={getMetaDescription()} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={getCanonicalUrl()} />
                <meta property="og:site_name" content="Deals Daily" />

                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={getTitle()} />
                <meta name="twitter:description" content={getMetaDescription()} />

                {/* Additional SEO Meta Tags */}
                <meta name="robots" content="index, follow" />
                {page > 1 && <meta name="robots" content="noindex, follow" />}
                <meta name="keywords" content={`deals, coupons, discounts, ${Categorties_data.map((e) => e.category).join(', ')}`} />

                {/* Pagination Meta Tags */}
                {page > 1 && (
                    <link
                        rel="prev"
                        href={`${getCanonicalUrl()}?page=${page - 1}`}
                    />
                )}
                {CouponCategory_data.length === 20 && (
                    <link
                        rel="next"
                        href={`${getCanonicalUrl()}?page=${page + 1}`}
                    />
                )}
            </Helmet>}
            <div className="min-h-screen lg:px-20 md:px-10 px-3 py-3 bg-black/5 grid sm:grid-cols-[auto_1fr] gap-3">
                <div className="bg-white sm:w-56 relative border h-fit sm:py-10 py-5 text-left z-20">
                    <h2 className="text-lg font-bold text-gray-800 mb-3 px-6 flex items-center justify-between">
                        <span>Top Coupon Categories</span>
                        <div onClick={() => setHamburgerMenu(!hamburgerMenu)} className="max-sm:grid hidden gap-[5px] cursor-pointer active:scale-90">
                            <div className={clsx(
                                "h-[3px] w-8",
                                hamburgerMenu ? "bg-purple-500" : "bg-black"
                            )}></div>
                            <div className={clsx(
                                "h-[3px] w-8",
                                hamburgerMenu ? "bg-purple-500" : "bg-black"
                            )}></div>
                            <div className={clsx(
                                "h-[3px] w-8",
                                hamburgerMenu ? "bg-purple-500" : "bg-black"
                            )}></div>
                        </div>
                    </h2>
                    {Categorties_loading !== 3 && <div className="px-6 grid gap-4">
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                        <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                    </div>}

                    {Categorties_loading === 3 && <div className={clsx(
                        "max-sm:absolute top-full w-full bg-white max-sm:shadow-lg",
                        hamburgerMenu ? "max-sm:h-[50vh] max-sm:overflow-y-auto" : "max-sm:h-0 max-sm:overflow-hidden"
                    )}>
                            <Link 
                                to={`/coupons`} 
                                key={"001"} 
                                className={clsx(
                                    "w-full px-6 block py-3 border-b last:border-none group hover:bg-black/5",
                                )}>
                                <span className={clsx(
                                    "flex items-center justify-between text-gray-800",
                                )}>
                                    All Top Coupons <ChevronRight className='group-hover:rotate-12 group-active:rotate-90' />
                                </span>
                            </Link>
                        {Categorties_data.map((category)=> (
                            <Link 
                                to={`/coupons/${category.category}`} 
                                key={category.category} 
                                className={clsx(
                                    "w-full px-6 block py-3 border-b last:border-none group",
                                    category.category === id ? "font-semibold bg-purple-500 text-white" : "hover:bg-black/5"
                                )}>
                                <span className={clsx(
                                    "flex items-center justify-between",
                                    category.category === id ? "text-white" : "text-gray-800"
                                )}>
                                    {category.category} <ChevronRight className='group-hover:rotate-12 group-active:rotate-90' />
                                </span>
                            </Link>
                        ))}
                    </div>}
                </div>
                <div className="flex flex-col gap-3">
                    <div className="bg-white text-left p-10 border">
                        <h1 className="text-2xl font-semibold">Get the Best Coupons for <span className="text-purple-700 italic">`{id}`</span> Category</h1>
                        <p className="sm:max-w-[80%] mt-3">
                            Find exclusive coupons to save on all things in <span className="text-purple-700 font-semibold italic">`{id}`</span> Category! Enjoy top discounts and deals tailored just for you. Start saving now!
                        </p>
                    </div>
                    <SearchBar />
                    {CouponCategory_loading === 3 && CouponCategory_data.length > 0 && <div className="bg-white p-10 gap-4 sm:grid-cols-[repeat(auto-fill,minmax(15rem,1fr))] grid-cols-[repeat(auto-fill,minmax(12rem,1fr))] grid border">
                        {CouponCategory_data.map((coupon, index) => (
                            <CouponCard
                                key={index}
                                code={coupon.code}
                                expiry={coupon.expiration_date}
                                logo={coupon.logo_image_url}
                                grabCarrot={()=>{
                                    setModal((prev) => ({ ...prev, isOpen: true, data: { ...coupon } }));
                                    navigator.clipboard.writeText(coupon.code);
                                    openInNewTab(coupon.redirect_url);
                                }}
                                merchant={coupon.merchant_name}
                                title={coupon.name}
                                className="w-full"
                                merchantId={""}
                            />
                        ))}
                    </div>}

                    {CouponCategory_loading === 3 && CouponCategory_data.length === 0 && <div className="bg-white p-10 grid border">
                        <span className="text-2xl opacity-60">No Data</span>
                    </div>}
                    {CouponCategory_loading === 2 && <div className="bg-white p-10 grid border">
                        <span className="text-2xl text-red-500">{CouponCategory_error}</span>
                    </div>}

                    {CouponCategory_loading !== 3 && <div className="bg-white md:p-10 p-3 sm:grid-cols-[repeat(auto-fill,minmax(15rem,1fr))] grid-cols-[repeat(auto-fill,minmax(12rem,1fr))] grid gap-4 border">
                        {new Array(10).fill("").map((_, $) => (
                            <div key={$} className="h-fit animate-pulse border shadow-lg rounded-md">
                                <div className="border-b">
                                    <DealImage
                                        src=""
                                        alt=""
                                        className="w-full h-48 object-cover"
                                    />
                                </div>
                                <div className="p-4 grid gap-1">
                                    <div className="p-1 bg-black/10 animate-pulse rounded-2xl">{_}</div>
                                    <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                                </div>
                                <div className="px-4 grid grid-cols-2 gap-1">
                                    <div className="p-1 bg-black/10 animate-pulse rounded-2xl">{_}</div>
                                    <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                                </div>
                                <div className="p-4 grid gap-1">
                                    <div className="p-2 bg-purple-500 animate-pulse rounded-2xl text-center text-white">Loading</div>
                                </div>
                            </div>
                        ))}
                    </div>}
                    {CouponCategory_data_pagination.totalPages > 1 && < PaginatedList forcePage={page ? Number(page) - 1 : 0} dispatchThunk={CategoryNextCouponsThunk} pageCount={CouponCategory_data_pagination.totalPages} />}
                </div>
            </div>
            <AnimatePresence>
                {modal.isOpen && <Modal data={modal.data} handleClickAway={() => setModal((prev) => ({ ...prev, isOpen: false }))} />}
            </AnimatePresence>
        </>
    )
}
