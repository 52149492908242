import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../Store";
import { useEffect, useState } from "react";
import DealImage from "../components/DealImage";
import { useNavigate, useParams } from "react-router-dom";
import { CouponCard } from "./sub components/CouponCard";
import { MerchantCouponsThunk } from "../Store/Marchant/coupons/Thunk";
import { $MerchantCoupons_data, $MerchantCoupons_error, $MerchantCoupons_loading } from "../Store/Marchant/coupons/Slice";
import { Merchant } from "../utilities/Interfaces/Homepage-top-coupons";
import { AnimatePresence } from "framer-motion";
import Modal from "../components/Modal";
import { MerchantThunk } from "../Store/Marchant/Thunk";
import { $Merchant_data, $Merchant_loading } from "../Store/Marchant/Slice";
import { Helmet } from "react-helmet";
import { openInNewTab } from "../utilities/important";
import SearchBar from "./sub components/SearchBar";

export default function MerchantCouponsPage() {
    const { id } = useParams<{ id: string }>();
    const [modal, setModal] = useState<{
        isOpen: boolean,
        data: Merchant,
    }>({
        isOpen: false,
        data: {} as Merchant
    });
    const dispatch = useDispatch<AppDispatch>();

    const Merchant_loading = useSelector($Merchant_loading);
    const Merchant_data = useSelector($Merchant_data);

    const MerchantCoupons_loading = useSelector($MerchantCoupons_loading);
    const MerchantCoupons_data = useSelector($MerchantCoupons_data);
    const MerchantCoupons_error = useSelector($MerchantCoupons_error);

    const isLoaded = Merchant_loading === 3 && Merchant_data && !Object.keys(Merchant_data).includes("message");
    
    const navigate = useNavigate();

    useEffect(() => {
        if(Merchant_loading === 3 && !isLoaded) {
            navigate(-1);
        }
    }, [Merchant_loading, isLoaded, Merchant_data]);

    useEffect(() => {
        dispatch(MerchantThunk(Number(id)));
        dispatch(MerchantCouponsThunk(Number(id)))
    }, [dispatch]);


    return (
        <div className="min-h-screen px-5 py-5 bg-black/5 grid gap-3">
            {Merchant_data && <Helmet>
                {Merchant_data.mcp.Merchant_Info && <title>{`${Merchant_data.mcp.Merchant_Info.Merchant_Name} Coupons ${new Date().getFullYear()}`}</title>}
                {Merchant_data.mcp.about ? <meta name="description" content={Merchant_data.mcp.about.content} /> : <meta name="description" content={Merchant_data.mcp.Merchant_Info.businessType} />}
                <meta property="og:title" content={`${Merchant_data.mcp.Merchant_Info.Merchant_Name} Coupons & Deals 2024 | Save on Top Products with Discounts}`} />
                <meta property="og:description" content={Merchant_data.mcp.about ? Merchant_data.mcp.about.content : Merchant_data.mcp.Merchant_Info.businessType} />
                <meta property="og:image" content={Merchant_data.logo_image_url} />
                <meta name="twitter:card" content="summary_large_image" />
                {Merchant_data.mcp.Merchant_Info && <meta name="twitter:title" content={`${Merchant_data.mcp.Merchant_Info.Merchant_Name} Coupons & Deals 2024 | Save on Top Products with Discounts}`} />}
                <meta name="twitter:description" content={Merchant_data.mcp.about ? Merchant_data.mcp.about.content : Merchant_data.mcp.Merchant_Info.businessType} />
                <meta name="twitter:image" content={Merchant_data.logo_image_url} />
                {Merchant_data.mcp?.seoKeywords && <meta name="keywords" content={`${`${Merchant_data.mcp.seoKeywords.join(', ')}`}`} />}
            </Helmet>}
            <div className="flex flex-col gap-3">
                {isLoaded && <div className="bg-white text-left p-10 border">
                    <h1 className="text-2xl font-semibold">{Merchant_data.name}'s Promo Codes & Coupons</h1>
                    {Merchant_data.mcp.about && <p className="">
                        {Merchant_data.mcp.about.content}
                    </p>}
                </div>}
                <SearchBar />
                {Merchant_loading === 1 && <div className="bg-white text-left p-10 border flex flex-col gap-2">
                    <h1 className="p-2 bg-black/10 animate-pulse rounded-2xl"></h1>
                    <p className="max-w-[60%] grid gap-1">
                        <p className="p-1 bg-black/10 animate-pulse rounded-2xl"></p>
                        <p className="p-1 bg-black/10 animate-pulse rounded-2xl"></p>
                        <p className="p-1 bg-black/10 animate-pulse rounded-2xl"></p>
                    </p>
                </div>}
                {MerchantCoupons_loading === 3 && MerchantCoupons_data.length > 0 && <div className="bg-white md:p-10 p-3 sm:grid-cols-[repeat(auto-fill,minmax(15rem,1fr))] grid-cols-[repeat(auto-fill,minmax(12rem,1fr))] grid border gap-4">
                    {MerchantCoupons_data.map((deal, index) => (
                        <CouponCard
                            key={index}
                            code={deal.code}
                            merchant={deal.merchant_name}
                            expiry={deal.expiration_date}
                            logo={deal.logo_image_url}
                            className="w-full"
                            title={deal.name}
                            grabCarrot={() => {
                                setModal((prev) => ({ ...prev, isOpen: true, data: deal as Merchant}));
                                navigator.clipboard.writeText(deal.code);
                                openInNewTab(deal.redirect_url)
                            }}
                        />
                    ))}
                </div>}
                {MerchantCoupons_loading === 3 && MerchantCoupons_data.length === 0 && <div className="bg-white p-10 grid border">
                    <span className="text-2xl opacity-60">No Data</span>
                </div>}
                {MerchantCoupons_loading === 2 && <div className="bg-white p-10 grid border">
                    <span className="text-2xl text-red-500">{MerchantCoupons_error}</span>
                </div>}
                {MerchantCoupons_loading === 1 && <div className="bg-white md:p-10 p-3 sm:grid-cols-[repeat(auto-fill,minmax(15rem,1fr))] grid-cols-[repeat(auto-fill,minmax(12rem,1fr))] grid gap-4 border">
                    {new Array(10).fill("").map((_, $) => (
                        <div key={$} className="h-fit animate-pulse border shadow-lg rounded-md">
                            <div className="border-b">
                                <DealImage
                                    src=""
                                    alt=""
                                    className="w-full h-48 object-cover"
                                />
                            </div>
                            <div className="p-4 grid gap-1">
                                <div className="p-1 bg-black/10 animate-pulse rounded-2xl">{_}</div>
                                <div className="p-1 bg-black/10 animate-pulse rounded-2xl"></div>
                            </div>
                            <div className="px-4 pb-4 grid gap-1">
                                <div className="p-2 bg-purple-500 animate-pulse rounded-2xl text-center text-white">Loading</div>
                            </div>
                        </div>
                    ))}
                </div>}
            </div>
            <AnimatePresence>
                {modal.isOpen && <Modal data={modal.data} handleClickAway={() => setModal((prev) => ({ ...prev, isOpen: false }))} />}
            </AnimatePresence>
        </div>
    )
}