import { configureStore } from "@reduxjs/toolkit";
import { HomepageTopCouponsReducer } from "./Homepage-top-coupons/Slice";
import { HomepageTopProductsReducer } from "./Homepage-top-products/Slice";
import { HomepageTopMerchantsReducer } from "./Homepage-top-merchants/Slice";
import { MerchantReducer } from "./Marchant/Slice";
import { MerchantCouponsReducer } from "./Marchant/coupons/Slice";
import { MerchantProductsReducer } from "./Marchant/products/Slice";
import { CategortiesReducer } from "./Categories/Slice";
import { ProductsReducer } from "./Products/Slice";
import { ProductCategoryReducer } from "./ProductCategory/Slice";
import { CouponsReducer } from "./Coupons/Slice";
import { CouponCategoryReducer } from "./CouponsCategory/Slice";
import { MerchantsReducer } from "./Merchants/Slice";
import { MerchantCategoryReducer } from "./MerchantCategory/Slice";

const store = configureStore({
    reducer: {
        HomepageTopCoupons: HomepageTopCouponsReducer,
        HomepageTopProducts: HomepageTopProductsReducer,
        HomepageTopMerchants: HomepageTopMerchantsReducer,
        Merchant: MerchantReducer,
        MerchantCoupons:  MerchantCouponsReducer,
        MerchantProducts:  MerchantProductsReducer,
        Categorties: CategortiesReducer,
        Products: ProductsReducer,
        ProductsCategory: ProductCategoryReducer,
        Coupons: CouponsReducer,
        CouponsCategory: CouponCategoryReducer,
        Merchants: MerchantsReducer,
        MerchantsCategory: MerchantCategoryReducer,
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;