import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../../utilities/important";
import { Product } from "../../../../utilities/Interfaces/Homepage-top-products";

export const MerchantProductsThunk = createAsyncThunk(
    "Merchant-products/thunk",
    async (id: number): Promise<Product[]> => {
        if (!baseUrl) throw new Error("baseUrl is not defined");

        const url = `${baseUrl}/merchant/${id}/products`;
        const response = await fetch(url);
        const data = await response.json();
        return data;
    }
)