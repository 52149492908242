import React from 'react';

interface DealsBunnyLogoProps {
    width?: number;
    height?: number;
    className?: string;
}

const DealsBunnyLogo: React.FC<DealsBunnyLogoProps> = ({
    width = 400,
    height = 200,
    className = ''
}) => {
    return (
        <svg
            viewBox="0 0 400 200"
            width={width}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
        >
            {/* Background Glow Effect */}
            <defs>
                <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
                    <feGaussianBlur stdDeviation="4" result="coloredBlur" />
                    <feMerge>
                        <feMergeNode in="coloredBlur" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>

            {/* Gaming Style Bunny */}
            <g filter="url(#glow)">
                {/* Main Head */}
                <path
                    d="M80 60 Q 80 20 120 50 L 160 50 Q 200 20 200 60 Q 200 100 160 110 L 120 110 Q 80 100 80 60Z"
                    fill="#9B4DE3"
                    stroke="#6B2BA3"
                    strokeWidth="4"
                />

                {/* Ears with Geometric Style */}
                <path
                    d="M110 45 L 100 15 Q 95 10 105 40"
                    fill="#9B4DE3"
                    stroke="#6B2BA3"
                    strokeWidth="4"
                />
                <path
                    d="M170 45 L 180 15 Q 185 10 175 40"
                    fill="#9B4DE3"
                    stroke="#6B2BA3"
                    strokeWidth="4"
                />

                {/* Eyes (Game Controller Shapes) */}
                <rect
                    x="100"
                    y="65"
                    width="25"
                    height="15"
                    rx="7"
                    fill="#50E3C2"
                />
                <rect
                    x="155"
                    y="65"
                    width="25"
                    height="15"
                    rx="7"
                    fill="#50E3C2"
                />

                {/* Cute Nose */}
                <polygon
                    points="135,85 145,85 140,95"
                    fill="#B19CD9"
                />

                {/* Geometric Cheek Accents (Light Pink) */}
                <rect
                    x="95"
                    y="90"
                    width="10"
                    height="10"
                    rx="2"
                    fill="#FFB6C1"
                    opacity="0.7"
                />
                <rect
                    x="175"
                    y="90"
                    width="10"
                    height="10"
                    rx="2"
                    fill="#FFB6C1"
                    opacity="0.7"
                />
            </g>

            {/* Text with Gaming Style */}
            <g transform="translate(0, 140)">
                <text
                    x="200"
                    y="0"
                    fontFamily="Arial Black"
                    fontSize="48"
                    fontWeight="bold"
                    textAnchor="middle"
                    fill="#9B4DE3"
                    stroke="#6B2BA3"
                    strokeWidth="2"
                    filter="url(#glow)"
                >
                    DEALS BUNNY
                </text>
            </g>
        </svg>
    );
};

export default DealsBunnyLogo;