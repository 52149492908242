import { createSlice } from "@reduxjs/toolkit";
import { AllCouponsThunk, NextCouponsThunk } from "../Thunk";
import { RootState } from "../..";
import { Response } from "../../../utilities/Interfaces/Coupons";

interface initialState {
    loading: 0 | 1 | 2 | 3;
    error?: string;
    result: Response
}

const initialState: initialState = {
    loading: 0,
    error: undefined,
    result: {
        currentPage: 0,
        data: [],
        totalItems: 0,
        totalPages: 0
    }
}

const CouponsSlice = createSlice({
    name: "Coupons",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(AllCouponsThunk.pending,  (state) => {
            state.loading =  1;
            state.error = undefined;
        })

        builder.addCase(AllCouponsThunk.rejected,  (state, action) => {
            state.loading = 2;
            state.error =  action.error.message;
        })

        builder.addCase(AllCouponsThunk.fulfilled,   (state, action) => {
            state.loading = 3;
            state.result = action.payload;
        });
        builder.addCase(NextCouponsThunk.pending,  (state) => {
            state.loading =  1;
            state.error = undefined;
        })

        builder.addCase(NextCouponsThunk.rejected,  (state, action) => {
            state.loading = 2;
            state.error =  action.error.message;
        })

        builder.addCase(NextCouponsThunk.fulfilled,   (state, action) => {
            state.loading = 3;
            state.result = action.payload;
        });
    }
})

export const $Coupons_loading = (state:RootState) => state.Coupons.loading;
export const $Coupons_error = (state:RootState) => state.Coupons.error;
export const $Coupons_data = (state:RootState) => state.Coupons.result?.data;
export const $Coupons_data_totalPages = (state:RootState) => state.Coupons.result?.totalPages;
export const $Coupons_data_totalCoupons = (state:RootState) => state.Coupons.result?.totalItems;

export const CouponsReducer = CouponsSlice.reducer