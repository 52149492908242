import { createSlice } from "@reduxjs/toolkit";
import { HomepageTopProductsThunk, Result } from "../Thunk";
import { RootState } from "../..";

interface initialState {
    loading: 0 | 1 | 2 | 3;
    error?: string;
    result: Result;
}
const initialState: initialState = {
    loading: 0,
    error: undefined,
    result: {
        currentPage: 0,
        data: [],
        totalItems: 0,
        totalPages: 0
    }
}

const HomepageTopProducts = createSlice({
    name: "Homepage-top-products",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(HomepageTopProductsThunk.pending,  (state) => {
            state.loading =  1;
            state.error = undefined;
        })

        builder.addCase(HomepageTopProductsThunk.rejected,  (state, action) => {
            state.loading = 2;
            state.error =  action.error.message;
        })

        builder.addCase(HomepageTopProductsThunk.fulfilled,   (state, action) => {
            state.loading = 3;
            state.result = action.payload;
        });
    }
})

export const $HomepageTopProducts_loading = (state:RootState) => state.HomepageTopProducts.loading;
export const $HomepageTopProducts_error = (state:RootState) => state.HomepageTopProducts.error;
export const $HomepageTopProducts_data = (state:RootState) => state.HomepageTopProducts.result.data;

export const HomepageTopProductsReducer = HomepageTopProducts.reducer