import { motion } from "framer-motion";
import { X } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useClickAway } from "react-use";
import { Merchant } from "../utilities/Interfaces/Homepage-top-coupons";
import DealImage from "./DealImage";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { Coupon, CouponCat } from "../utilities/Interfaces/Coupons";

export default function Modal({ handleClickAway, data }: { handleClickAway: () => void, data: Merchant | Coupon | CouponCat }) {
    const mainRef = useRef<HTMLDivElement>(null);
    const [justCopied, setJustCopied] = useState(true);

    useEffect(() => {
        if(justCopied) {
            setTimeout(() => setJustCopied(false), 2000);
        }
    }, [justCopied]);
    useClickAway(mainRef, handleClickAway);

    const copyToClipboard = (text: string) => {
        if(justCopied)  return;

        navigator.clipboard.writeText(text);
        setJustCopied(true);
    };

    return (
        <motion.div
            className="fixed top-0 left-0 grid place-items-center backdrop-blur-sm bg-black/15 z-[100] h-screen w-screen overflow-hidden"
            title="Click here to close modal"
            initial={{
                opacity: 0,
            }}

            animate={{
                opacity: 1,
            }}

            exit={{
                opacity: 0,
            }}
        >
            <motion.div
                title=""
                ref={mainRef}
                transition={{
                    delay: 0.15,
                    duration: 0.15,
                    type: "spring"
                }}

                initial={{
                    opacity: 0,
                    y: 100
                }}
    
                animate={{
                    opacity: 1,
                    y: 0
                }}
    
                exit={{
                    opacity: 0,
                    y: 100
                }}
                className="bg-white max-h-[80dvh] max-w-[95dvw] rounded-2xl shadow-xl min-h-[40dvh] min-w-[40dvw] relative"
            >
                <button 
                    onClick={handleClickAway} 
                    title="Click here to close modal"
                    className="absolute shadow-lg shadow-black/25 hover:bg-red-700 hover:shadow-black/40 border border-white/25 hover:border-white/50 -right-4 -top-4 h-10 w-10 rounded-full overflow-hidden grid place-items-center text-white bg-purple-500 group cursor-pointer active:scale-90"
                >
                    <X  className="group-active:rotate-12"/>
                </button>
                <div className="flex flex-col items-center gap-3 px-10 py-20">
                    <div className="h-32 w-32 rounded-full overflow-hidden grid place-items-center border">
                        <DealImage
                            src={data.logo_image_url}
                            alt={data.merchant_name}
                            className="object-cover object-center h-full w-full"
                        />
                    </div>
                    <h2 className="text-2xl font-semibold">{data.name}</h2>
                    <div className="my-5">
                        <div className="flex text-3xl items-center p-1 border border-purple-600 rounded-[5rem] gap-6">
                            <span className="flex-1 font-semibold px-5">{data.code}</span>
                            <button 
                                onClick={()=>copyToClipboard(data.code)} 
                                className={clsx(
                                    "py-3 text-white px-5 rounded-[5rem] relative",
                                    justCopied ? "bg-purple-300" : "active:scale-90 bg-purple-600"
                                )}
                            >
                                <span className="opacity-0">COPIED</span>
                                <span className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">{justCopied ? "COPIED" : "COPY"}</span>
                            </button>
                        </div>
                        <p className="my-2 text-center">Copy and paste this code at <Link className="text-purple-700 capitalize font-medium active:scale-90" to={data.redirect_url}>{data.merchant_name}</Link></p>
                    </div>
                </div>
                <div className="flex items-center justify-between bg-gray-100 px-10 py-4 rounded-b-2xl">
                    <p>Ends <strong>{new Date(data.expiration_date ?? 0).toLocaleDateString()}</strong></p>
                    <p>Used <strong>{data.num_success ?? 0}</strong> times</p>
                </div>
            </motion.div>
        </motion.div>
    )
}