import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../../utilities/important";
import { Coupon } from "../../../../utilities/Interfaces/Merchant";


export const MerchantCouponsThunk = createAsyncThunk(
    "Merchant-coupons/thunk",
    async (id: number): Promise<Coupon[]> => {
        if (!baseUrl) throw new Error("baseUrl is not defined");

        const url = `${baseUrl}/merchant/${id}/coupons`;
        const response = await fetch(url);
        const data = await response.json();
        return data;
    }
)