import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../utilities/important";
import { CategoriesResponse } from "../../../utilities/Interfaces/Coupons";

export const CategoryCouponsThunk = createAsyncThunk(
    "Coupons/category-thunk",
    async ({ category }: { category: string }): Promise<CategoriesResponse> => {
        if (!baseUrl) throw new Error("baseUrl is not defined");

        const url = `${baseUrl}/category/${category}/coupons`;
        const response = await fetch(url);
        const data = await response.json();
        return data;
    }
)


export const CategoryNextCouponsThunk = createAsyncThunk(
    "Coupons/next-coupons-thunk",
    async ({ page, category }: { page: number, category: string }): Promise<CategoriesResponse> => {
        if (!baseUrl) throw new Error("baseUrl is not defined");
        const url = `${baseUrl}/category/${category}/coupons?page=${page}`;
        const response = await fetch(url);
        const data = await response.json();
        return data;
    }
)