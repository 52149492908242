import { createSlice } from "@reduxjs/toolkit";
import { MerchantThunk} from "../Thunk";
import { RootState } from "../..";
import { Merchant } from "../../../utilities/Interfaces/Merchant";

interface InitialState {
    loading: 0 | 1 | 2 | 3;
    error?: string;
    result?: Merchant
}

const initialState: InitialState = {
    loading: 0,
    error: undefined,
}

const MerchantSlice = createSlice({
    name: "MerchantSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(MerchantThunk.pending,  (state) => {
            state.loading =  1;
            state.error = undefined;
        })

        builder.addCase(MerchantThunk.rejected,  (state, action) => {
            state.loading = 2;
            state.error =  action.error.message;
        })

        builder.addCase(MerchantThunk.fulfilled,   (state, action) => {
            state.loading = 3;
            state.result = action.payload;
        });
    }
})

export const $Merchant_loading = (state:RootState) => state.Merchant.loading;
export const $Merchant_error = (state:RootState) => state.Merchant.error;
export const $Merchant_data = (state:RootState) => state.Merchant.result;

export const MerchantReducer = MerchantSlice.reducer