import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../utilities/important";
import { Category } from "../../../utilities/Interfaces/Products";

export interface Result {
    data: Category[],
    currentPage: number,
    totalPages: number,
    totalItems: number,
};

export const AllProductsThunk = createAsyncThunk(
    "Products/all-thunk",
    async (): Promise<Result> => {
        if (!baseUrl) throw new Error("baseUrl is not defined");

        const url = `${baseUrl}/all/products`;
        const response = await fetch(url);
        const data = await response.json();

        return data;
    }
)

export const NextProductsThunk = createAsyncThunk(
    "Products/next-products-thunk",
    async ({ page }: { page: number }): Promise<Result> => {
        if (!baseUrl) throw new Error("baseUrl is not defined");
        const url = `${baseUrl}/all/products?page=${page}`;
        const response = await fetch(url);
        const data = await response.json();
        
        return data;
    }
)