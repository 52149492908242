import { createSlice } from "@reduxjs/toolkit";
import { MerchantProductsThunk} from "../Thunk";
import { RootState } from "../../..";
import { Product } from "../../../../utilities/Interfaces/Merchant";

interface InitialState {
    loading: 0 | 1 | 2 | 3;
    error?: string;
    result: Product[];
}
const initialState: InitialState = {
    loading: 0,
    error: undefined,
    result: []
}

const MerchantProducts = createSlice({
    name: "Homepage-top-products",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(MerchantProductsThunk.pending,  (state) => {
            state.loading =  1;
            state.error = undefined;
        })

        builder.addCase(MerchantProductsThunk.rejected,  (state, action) => {
            state.loading = 2;
            state.error =  action.error.message;
        })

        builder.addCase(MerchantProductsThunk.fulfilled,   (state, action) => {
            state.loading = 3;
            state.result = action.payload;
        });
    }
})

export const $MerchantProducts_loading = (state:RootState) => state.MerchantProducts.loading;
export const $MerchantProducts_error = (state:RootState) => state.MerchantProducts.error;
export const $MerchantProducts_data = (state:RootState) => state.MerchantProducts.result;

export const MerchantProductsReducer = MerchantProducts.reducer