import { AsyncThunk } from "@reduxjs/toolkit";
import React from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store";
import { useLocation } from "react-router-dom";
import { useWindowSize } from "react-use";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { updateSearchPageParam } from "../../utilities/important";

interface PaginatedListProps {
    pageCount: number;
    dispatchThunk?: AsyncThunk<any, any,  any>;
    action?: (page: number) => void;
    forcePage: number;
    isNotDispatch?: boolean;
    urlParam?: string;
}

const PaginatedList: React.FC<PaginatedListProps> = ({ pageCount, dispatchThunk, forcePage, action, isNotDispatch, urlParam }) => {
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();

    const { width: windowResize } = useWindowSize();    

    const changeURL = (page: number) => {
        const path = location.pathname;
        urlParam ? updateSearchPageParam(String(page), 'product-page') : window.history.pushState({}, '', `${path}?page=${page}`);
    };

    const handlePageClick = (event: { selected: number }) => {
        const selectedPage = event.selected;
        changeURL(selectedPage + 1);
        if(isNotDispatch) {
            action && action(selectedPage + 1);
            return;
        }
        dispatchThunk && dispatch(dispatchThunk({ page: selectedPage + 1 }));
    };

    return (
        <div>
            <ReactPaginate
                breakLabel="..."
                nextLabel={windowResize > 720 ? <span className="flex items-center gap-2"><ChevronRight /> Next</span> : <ChevronRight />}
                className="flex justify-center items-center"
                onPageChange={handlePageClick}
                forcePage={forcePage}
                pageCount={pageCount}
                marginPagesDisplayed={windowResize > 1200 ? 3 : windowResize > 720 ? 2 : 1}
                pageRangeDisplayed={windowResize > 1200 ? 3 : windowResize > 720 ? 2 : 1}
                previousLabel={windowResize > 720 ? <span className="flex items-center gap-2"><ChevronLeft/> Previous</span> : <ChevronLeft />}
                previousClassName="mr-4 sm:px-6 px-3 text-sm py-2 bg-purple-500 text-white cursor-pointer active:scale-95"
                nextClassName="ml-4 sm:px-6 px-3 text-sm py-2 bg-purple-500 text-white cursor-pointer active:scale-95"
                containerClassName="pagination"
                breakClassName="px-3"
                pageLinkClassName="px-5 py-2 border"
                activeClassName="active py-2 border-none bg-purple-500 text-white h-full"
            />
        </div>
    );
};

export default PaginatedList;