import { MeiliSearch } from 'meilisearch'

export const baseUrl = process.env.REACT_APP_BASE_URL;


export const client = new MeiliSearch({
  host:  process.env.REACT_APP_MEILI_HOST!,
  apiKey:  process.env.REACT_APP_MEILI_API_KEY!
});

export const openInNewTab = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.rel = 'noopener noreferrer';
  link.click();
};

export const updateSearchPageParam = (paramNam: string, value: string) => {
  const url = new URL(window.location.href);  // Get the current URL
  const params = new URLSearchParams(url.search);  // Extract search parameters

  params.set(paramNam, value.toString());  // Update or add `product-page`

  window.history.pushState({}, '', `${url.pathname}?${params.toString()}`);
};