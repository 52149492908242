import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../utilities/important";
import { Merchant } from "../../../utilities/Interfaces/Merchants";

export interface Result {
    data: Merchant[],
    currentPage: number,
    totalPages: number,
    totalItems: number,
};

export const AllMerchantsThunk = createAsyncThunk(
    "Merchants/all-thunk",
    async (): Promise<Result> => {
        if (!baseUrl) throw new Error("baseUrl is not defined");

        const url = `${baseUrl}/all/merchants`;
        const response = await fetch(url);
        const data = await response.json();

        return data;
    }
)

export const NextMerchantsThunk = createAsyncThunk(
    "Merchants/next-Merchants-thunk",
    async ({ page }: { page: number }): Promise<Result> => {
        if (!baseUrl) throw new Error("baseUrl is not defined");
        const url = `${baseUrl}/all/merchants?page=${page}`;
        const response = await fetch(url);
        const data = await response.json();
        
        return data;
    }
)