import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../utilities/important";
import { Response } from "../../../utilities/Interfaces/Coupons";

export const AllCouponsThunk = createAsyncThunk(
    "Coupons/all-thunk",
    async (): Promise<Response> => {
        if (!baseUrl) throw new Error("baseUrl is not defined");

        const url = `${baseUrl}/all/coupons`;
        const response = await fetch(url);
        const data = await response.json();

        return data;
    }
)

export const NextCouponsThunk = createAsyncThunk(
    "Coupons/next-coupons-thunk",
    async ({ page }: { page: number }): Promise<Response> => {
        if (!baseUrl) throw new Error("baseUrl is not defined");
        const url = `${baseUrl}/all/coupons?page=${page}`;
        const response = await fetch(url);
        const data = await response.json();
        
        return data;
    }
)