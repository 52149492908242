import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../utilities/important";
import { Product } from "../../../utilities/Interfaces/Homepage-top-products";

export interface Result {
    data: Product[],
    currentPage: number,
    totalPages: number,
    totalItems: number,
};

export const HomepageTopProductsThunk = createAsyncThunk(
    "Homepage-top-products/thunk",
    async (): Promise<Result> => {
        if (!baseUrl) throw new Error("baseUrl is not defined");

        const url = `${baseUrl}/homepage/top-products`;
        const response = await fetch(url);
        const data = await response.json();
        return data;
    }
)