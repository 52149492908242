import { createSlice } from "@reduxjs/toolkit";
import { CategoryNextProductsThunk, CategoryProductsThunk } from "../Thunk";
import { RootState } from "../..";
import { Data } from "../../../utilities/Interfaces/Products";

interface InitialState {
    loading: 0 | 1 | 2 | 3;
    error?: string;
    result: Data
}

const initialState: InitialState = {
    loading: 0,
    error: undefined,
    result: {
        pagination: {
            currentPage: 0,
            hasNextPage:  false,
            hasPreviousPage:  false,
            totalPages:  0,
            totalProducts:   0
        },
        products: []
    }
}

const ProductCategorySlice = createSlice({
    name: "ProductsCategory",
    initialState,
    reducers: {},
    extraReducers: (builder) => {        
        builder.addCase(CategoryProductsThunk.pending,  (state) => {
            state.loading =  1;
            state.error = undefined;
        })

        builder.addCase(CategoryProductsThunk.rejected,  (state, action) => {
            state.loading = 2;
            state.error =  action.error.message;
        })

        builder.addCase(CategoryProductsThunk.fulfilled,   (state, action) => {
            const data = action.payload;

            if (data.pagination) {
                state.loading = 3;
                state.result = action.payload;
                return;
            }

            state.loading = 2;
            state.error = (data as any).message;
        });
        
        builder.addCase(CategoryNextProductsThunk.pending,  (state) => {
            state.loading =  1;
            state.error = undefined;
        })

        builder.addCase(CategoryNextProductsThunk.rejected,  (state, action) => {
            state.loading = 2;
            state.error =  action.error.message;
        })

        builder.addCase(CategoryNextProductsThunk.fulfilled,   (state, action) => {
            const data = action.payload;

            if (data.pagination) {
                state.loading = 3;
                state.result = action.payload;
                return;
            }

            state.loading = 2;
            state.error = (data as any).message;
        });
    }
})

export const $ProductCategory_loading = (state:RootState) => state.ProductsCategory.loading;
export const $ProductCategory_error = (state:RootState) => state.ProductsCategory.error;
export const $ProductCategory_data = (state:RootState) => state.ProductsCategory.result.products;
export const $ProductCategory_data_pagination = (state:RootState) => state.ProductsCategory.result.pagination;

export const ProductCategoryReducer = ProductCategorySlice.reducer