import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../utilities/important";
import { Merchant } from "../../../utilities/Interfaces/Merchant";

export const MerchantThunk = createAsyncThunk(
    "MerchantSlice/thunk",
    async (id: number): Promise<Merchant> => {
        if (!baseUrl) throw new Error("baseUrl is not defined");

        await new Promise(resolve => setTimeout(resolve, 2000));

        const url = `${baseUrl}/merchant/${id}`;
        console.log({url})
        const response = await fetch(url);
        const data = await response.json();
        return data;
    }
)