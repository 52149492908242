import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider, { Settings } from "react-slick";
import { ChevronLeft, ChevronRight } from "lucide-react";

export default function Carousel({ children, start = 5 }: { children: React.ReactNode, start?: number }) {
    var settings: Settings = {
        infinite: true,
        draggable: true,
        speed: 500,
        dots: true,
        pauseOnHover: true,
        slidesToShow: start,
        adaptiveHeight: false,
        slidesToScroll: start,
        swipeToSlide: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: start,
                    slidesToScroll: start,
                    initialSlide: start
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    initialSlide: 4
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 3
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ],
        initialSlide: 0,
        // fade: true,
        autoplaySpeed: 5000
    };
    return (
        <Slider className="w-full" autoplay {...settings}>
            {children} 
        </Slider>
    );
}

function NextArrow(props: React.HtmlHTMLAttributes<HTMLDivElement>) {
    const { onClick } = props;
    return (
        <div
            className="h-9 w-9 grid place-items-center absolute top-1/2 -translate-y-1/2 left-0 z-40 bg-purple-600 shadow-md rounded-full border border-black active:scale-95 cursor-pointer"
            onClick={onClick}
        >
            <ChevronLeft className="text-white" />
        </div>
    );
}

function PrevArrow(props: React.HtmlHTMLAttributes<HTMLDivElement>) {
    const { onClick } = props;
    return (
        <div
            className="h-9 w-9 grid place-items-center absolute top-1/2 -translate-y-1/2 right-0 z-40 bg-purple-600 shadow-md rounded-full border border-black active:scale-95 cursor-pointer"
            onClick={onClick}
        >
            <ChevronRight className="text-white" />
        </div>
    );
}
