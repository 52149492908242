import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../utilities/important";
import { Data } from "../../../utilities/Interfaces/Products";

export const CategoryProductsThunk = createAsyncThunk(
    "Products/category-thunk",
    async ({ category }: { category: string }): Promise<Data> => {
        if (!baseUrl) throw new Error("baseUrl is not defined");

        const url = `${baseUrl}/category/${category}/products`;
        const response = await fetch(url);
        const data = await response.json();
        return data;
    }
)


export const CategoryNextProductsThunk = createAsyncThunk(
    "Products/next-products-thunk",
    async ({ page, category }: { page: number, category: string }): Promise<Data> => {
        if (!baseUrl) throw new Error("baseUrl is not defined");
        const url = `${baseUrl}/category/${category}/products?page=${page}`;
        const response = await fetch(url);
        const data = await response.json();
        return data;
    }
)