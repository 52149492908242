import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import HomePage from './pages/HomePage';
import StoreProvider from './Store/Provider';
import ProductsPage from './pages/ProductsPage';
import Header from './components/Header';
import Footer from './components/Footer';
import CouponsPage from './pages/CouponsPage';
import ProductsPageCategory from './pages/ProductsPageCategory';
import MerchantPage from './pages/MerchantPage';
import MerchantProductsPage from './pages/MerchantProductsPage';
import MerchantCouponsPage from './pages/MerchantCouponsPage';
import CouponsPageCategory from './pages/CouponsPageCategory';
import SearchPage from './pages/SearchPage';
import MerchantsPage from './pages/MerchantsPage';
import MerchantsPageCategory from './pages/MerchantsPageCategory';

const App: React.FC = () => {
  return (
    <StoreProvider>
      <Router>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/products" element={<ProductsPage />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/products/:id" element={<ProductsPageCategory />} />
            <Route path="/coupons" element={<CouponsPage />} />
            <Route path="/coupons/:id" element={<CouponsPageCategory />} />
            <Route path="/merchant/:id" element={<MerchantPage />} />
            <Route path="/merchants" element={<MerchantsPage />} />
            <Route path="/merchants/:id" element={<MerchantsPageCategory />} />
            <Route path="/merchant/:id/products" element={<MerchantProductsPage />} />
            <Route path="/merchant/:id/coupons" element={<MerchantCouponsPage />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </StoreProvider>
  );
};

export default App;
